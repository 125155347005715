
// import React from 'react';
// import { Container, Button } from 'react-bootstrap';
// import 'swiper/swiper-bundle.min.css'; // Correct import for Swiper's styles
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Pagination, Navigation } from 'swiper';
// import SwiperCore from 'swiper';

// SwiperCore.use([Pagination, Navigation]);

// const IndustryTypes = () => {
//     return (
//         <Container className="mt-5">
//             <Swiper
//                 loop={true}
//                 spaceBetween={30}
//                 pagination={{
//                     clickable: true,
//                     dynamicBullets: true,
//                 }}
//                 navigation={true}
//                 breakpoints={{
//                     0: { slidesPerView: 1 },
//                     768: { slidesPerView: 2 },
//                     1024: { slidesPerView: 3 },
//                 }}
//             >
//                 {/* Card 1 */}
//                 <SwiperSlide>
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="./assest/aimg1.avif"
//                             alt="Designer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         {/* <Badge pill bg="primary" className="mb-3">
//               Designer
//             </Badge> */}

//                         <h5 className="text-center mb-3" style={{fontSize: '2.1rem' }}>
//                             Small Business
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto">
//                             Go to Page
//                         </Button>
//                     </div>
//                 </SwiperSlide>

//                 {/* Card 2 */}
//                 <SwiperSlide>
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="./assest/aimg2.avif"
//                             alt="Developer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         {/* <Badge pill bg="success" className="mb-3">
//               Developer
//             </Badge> */}
//                         <h5 className="text-center mb-3"  style={{fontSize: '2.1rem' }}>
//                             SMEs
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto">
//                             Go to Page
//                         </Button>
//                     </div>
//                 </SwiperSlide>

//                 {/* Card 3 */}
//                 <SwiperSlide>  style={{fontSize: '2.1rem' }}
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="./assest/aimg3.avif"
//                             alt="Marketer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         {/* <Badge pill bg="warning" className="mb-3">
//               Marketer
//             </Badge> */}
//                         <h5 className="text-center mb-3"  style={{fontSize: '2.1rem' }}>
//                             Enterprises
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto">
//                             Go to Page
//                         </Button>
//                     </div>
//                 </SwiperSlide>
//                 {/* Card 4 */}
//                 <SwiperSlide>
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="./assest/aimg4.avif"
//                             alt="Marketer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         {/* <Badge pill bg="warning" className="mb-3">
//               Marketer
//             </Badge> */}
//                         <h5 className="text-center mb-3"  style={{fontSize: '2.1rem' }}>
//                             Technology
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto">
//                             Go to Page
//                         </Button>
//                     </div>
//                 </SwiperSlide>
//                 {/* Card 5 */}
//                 <SwiperSlide>
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="images/marketer.jpg"
//                             alt="Marketer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         {/* <Badge pill bg="warning" className="mb-3">
//               Marketer
//             </Badge> */}
//                         <h5 className="text-center mb-3"  style={{fontSize: '2.1rem' }}>
//                             Healthcare
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto">
//                             Go to Page
//                         </Button>
//                     </div>
//                 </SwiperSlide>

//                 {/* Card 6 */}
//                 <SwiperSlide>
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="images/marketer.jpg"
//                             alt="Marketer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         {/* <Badge pill bg="warning" className="mb-3">
//               Marketer
//             </Badge> */}
//                         <h5 className="text-center mb-3"  style={{fontSize: '2.1rem' }}>
//                             Hospitality
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto">
//                             Go to Page
//                         </Button>
//                     </div>
//                 </SwiperSlide>
//                 {/* Card 7 */}
//                 <SwiperSlide>
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="images/marketer.jpg"
//                             alt="Marketer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         {/* <Badge pill bg="warning" className="mb-3">
//               Marketer
//             </Badge> */}
//                         <h5 className="text-center mb-3"  style={{fontSize: '2.1rem' }}>
//                             Professional
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto">
//                             Go to Page
//                         </Button>
//                     </div>
//                 </SwiperSlide>
//                 {/* Card 8 */}
//                 <SwiperSlide>
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="images/marketer.jpg"
//                             alt="Marketer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         {/* <Badge pill bg="warning" className="mb-3">
//               Marketer
//             </Badge> */}
//                         <h5 className="text-center mb-3"  style={{fontSize: '2.1rem' }}>
//                             Consultant
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto">
//                             Go to Page
//                         </Button>
//                     </div>
//                 </SwiperSlide>
//                 {/* Card 9 */}
//                 <SwiperSlide>
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="images/marketer.jpg"
//                             alt="Marketer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         {/* <Badge pill bg="warning" className="mb-3">
//               Marketer
//             </Badge> */}
//                         <h5 className="text-center mb-3"  style={{fontSize: '2.1rem' }}>
//                             Startups
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto">
//                             Go to Page
//                         </Button>
//                     </div>
//                 </SwiperSlide>
//                 {/* Card 10 */}
//                 <SwiperSlide>
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="images/marketer.jpg"
//                             alt="Marketer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         {/* <Badge pill bg="warning" className="mb-3">
//               Marketer
//             </Badge> */}
//                         <h5 className="text-center mb-3"  style={{fontSize: '2.1rem' }}>
//                             Retail
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto">
//                             Go to Page
//                         </Button>
//                     </div>
//                 </SwiperSlide>
//                 {/* Card 11 */}
//                 <SwiperSlide>
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="images/marketer.jpg"
//                             alt="Marketer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         {/* <Badge pill bg="warning" className="mb-3">
//               Marketer
//             </Badge> */}
//                         <h5 className="text-center mb-3"  style={{fontSize: '2.1rem' }}>
//                             Manufacturing
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto">
//                             Go to Page
//                         </Button>
//                     </div>
//                 </SwiperSlide>
//                 {/* Card 12 */}
//                 <SwiperSlide>
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="images/marketer.jpg"
//                             alt="Marketer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         {/* <Badge pill bg="warning" className="mb-3">
//               Marketer
//             </Badge> */}
//                         <h5 className="text-center mb-3"  style={{fontSize: '2.1rem' }}>
//                             Real Estate
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto">
//                             Go to Page
//                         </Button>
//                     </div>
//                 </SwiperSlide>

//             </Swiper>
//         </Container>
//     );
// };

// export default IndustryTypes;


// import React from 'react';
// import { Container, Button } from 'react-bootstrap';
// import 'swiper/swiper-bundle.min.css'; // Correct import for Swiper's styles
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Pagination, Navigation } from 'swiper';
// import SwiperCore from 'swiper';

// SwiperCore.use([Pagination, Navigation]);

// const IndustryTypes = () => {
//     return (
//         <Container className="mt-5 mb-5">
//             <Swiper
//                 loop={true}
//                 spaceBetween={30}
//                 pagination={{
//                     clickable: true,
//                     dynamicBullets: true,
//                 }}
//                 navigation={true}
//                 breakpoints={{
//                     0: { slidesPerView: 1 },
//                     768: { slidesPerView: 2 },
//                     1024: { slidesPerView: 3 },
//                 }}
//             >
//                 {/* Card 1 */}
//                 <SwiperSlide>
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="./assest/simg1.avif"
//                             alt="Designer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
//                             Small Business
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto " style={{ fontSize: '1.3rem' }}>
//                             Go to Page 
//                         </Button>
//                     </div>
//                 </SwiperSlide>

//                 {/* Card 2 */}
//                 <SwiperSlide>
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="./assest/simg2.avif"
//                             alt="Developer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
//                             SMEs
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto"  style={{ fontSize: '1.3rem' }}>
//                             Go to Page
//                         </Button>
//                     </div>
//                 </SwiperSlide>

//                 {/* Card 3 */}
//                 <SwiperSlide>
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="./assest/simg3.avif"
//                             alt="Marketer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
//                             Enterprises
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto" style={{ fontSize: '1.3rem' }}>
//                             Go to Page
//                         </Button>
//                     </div>
//                 </SwiperSlide>

//                 {/* Card 4 */}
//                 <SwiperSlide>
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="./assest/simg4.avif"
//                             alt="Marketer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
//                             Technology
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto" style={{ fontSize: '1.3rem' }}>
//                             Go to Page
//                         </Button>
//                     </div>
//                 </SwiperSlide>

//                 {/* Card 5 */}
//                 <SwiperSlide>
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="./assest/simg1.avif"
//                             alt="Marketer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
//                             Healthcare
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto" style={{ fontSize: '1.3rem' }}>
//                             Go to Page
//                         </Button>
//                     </div>
//                 </SwiperSlide>

//                 {/* Card 6 */}
//                 <SwiperSlide>
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="./assest/simg2.avif"
//                             alt="Marketer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
//                             Hospitality
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto" style={{ fontSize: '1.3rem' }}>
//                             Go to Page
//                         </Button>
//                     </div>
//                 </SwiperSlide>

//                 {/* Card 7 */}
//                 <SwiperSlide>
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="./assest/simg3.avif"
//                             alt="Marketer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
//                             Professional
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto" style={{ fontSize: '1.3rem' }}>
//                             Go to Page
//                         </Button>
//                     </div>
//                 </SwiperSlide>

//                 {/* Card 8 */}
//                 <SwiperSlide>
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="./assest/simg4.avif"
//                             alt="Marketer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
//                             Consultant
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto" style={{ fontSize: '1.3rem' }}>
//                             Go to Page
//                         </Button>
//                     </div>
//                 </SwiperSlide>

//                 {/* Card 9 */}
//                 <SwiperSlide>
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="./assest/simg1.avif"
//                             alt="Marketer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
//                             Startups
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto" style={{ fontSize: '1.3rem' }}>
//                             Go to Page
//                         </Button>
//                     </div>
//                 </SwiperSlide>

//                 {/* Card 10 */}
//                 <SwiperSlide>
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="./assest/simg2.avif"
//                             alt="Marketer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
//                             Retail
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto" style={{ fontSize: '1.3rem' }}>
//                             Go to Page
//                         </Button>
//                     </div>
//                 </SwiperSlide>

//                 {/* Card 11 */}
//                 <SwiperSlide>
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="./assest/simg3.avif"
//                             alt="Marketer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
//                             Manufacturing
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto" style={{ fontSize: '1.3rem' }}>
//                             Go to Page
//                         </Button>
//                     </div>
//                 </SwiperSlide>

//                 {/* Card 12 */}
//                 <SwiperSlide>
//                     <div className="card-item p-3 bg-white border rounded-3 shadow-sm">
//                         <img
//                             src="./assest/simg4.avif"
//                             alt="Marketer"
//                             className="w-100 mb-3 rounded-3"
//                             style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
//                         />
//                         <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
//                             Real Estate
//                         </h5>
//                         <Button variant="Link" className="d-block mx-auto" style={{ fontSize: '1.3rem' }}>
//                             Go to Page
//                         </Button>
//                     </div>
//                 </SwiperSlide>
//             </Swiper>
//         </Container>
//     );
// };

// export default IndustryTypes;


import React from 'react';
import { Container, Button } from 'react-bootstrap';
import 'swiper/swiper-bundle.min.css'; // Correct import for Swiper's styles
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import SwiperCore from 'swiper';

SwiperCore.use([Pagination, Navigation]);

const IndustryTypes = () => {
    return (
        <Container className="mt-5 mb-5" >
            <Swiper
                loop={true}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                    dynamicBullets: true,
                }}
                navigation={true}
                breakpoints={{
                    0: { slidesPerView: 1 },
                    768: { slidesPerView: 2 },
                    1024: { slidesPerView: 3 },
                }}
            >
                {/* Card 1 */}
                <SwiperSlide >
                    <div className="card-item p-3 bg-white rounded-3 shadow-sm" style={{ border: '2px solid #B59BCD' }}>
                        <img
                            src="./assest/simg1.jpg"
                            alt="Small Business"
                            className="w-100 mb-3 rounded-3"
                            style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
                        />
                        <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
                            Small Business
                        </h5>
                        <Button variant="Link" className="d-block mx-auto" style={{ fontSize: '1.3rem' }}>
                            Go to Page &gt;
                        </Button>
                    </div>
                </SwiperSlide>

                {/* Card 2 */}
                <SwiperSlide>
                    <div className="card-item p-3 bg-white rounded-3 shadow-sm" style={{ border: '2px solid #B59BCD' }}>
                        <img
                            src="./assest/simg2.jpg"
                            alt="SMEs"
                            className="w-100 mb-3 rounded-3"
                            style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
                        />
                        <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
                            SMEs
                        </h5>
                        <Button variant="Link" className="d-block mx-auto" style={{ fontSize: '1.3rem' }}>
                            Go to Page &gt;
                        </Button>
                    </div>
                </SwiperSlide>

                {/* Card 3 */}
                <SwiperSlide>
                    <div className="card-item p-3 bg-white rounded-3 shadow-sm" style={{ border: '2px solid #B59BCD' }}>
                        <img
                            src="./assest/simg3.jpg"
                            alt="Enterprises"
                            className="w-100 mb-3 rounded-3"
                            style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
                        />
                        <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
                            Enterprises
                        </h5>
                        <Button variant="Link" className="d-block mx-auto" style={{ fontSize: '1.3rem' }}>
                            Go to Page &gt;
                        </Button>
                    </div>
                </SwiperSlide>

                {/* Card 4 */}
                <SwiperSlide>
                    <div className="card-item p-3 bg-white rounded-3 shadow-sm" style={{ border: '2px solid #B59BCD' }}>
                        <img
                            src="./assest/simg4.jpg"
                            alt="Technology"
                            className="w-100 mb-3 rounded-3"
                            style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
                        />
                        <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
                            Technology
                        </h5>
                        <Button variant="Link" className="d-block mx-auto" style={{ fontSize: '1.3rem' }}>
                            Go to Page &gt;
                        </Button>
                    </div>
                </SwiperSlide>

                {/* Card 5 */}
                <SwiperSlide>
                    <div className="card-item p-3 bg-white rounded-3 shadow-sm" style={{ border: '2px solid #B59BCD' }}>
                        <img
                            src="./assest/simg1.jpg"
                            alt="Healthcare"
                            className="w-100 mb-3 rounded-3"
                            style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
                        />
                        <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
                            Healthcare
                        </h5>
                        <Button variant="Link" className="d-block mx-auto" style={{ fontSize: '1.3rem' }}>
                            Go to Page &gt;
                        </Button>
                    </div>
                </SwiperSlide>

                {/* Card 6 */}
                <SwiperSlide>
                    <div className="card-item p-3 bg-white rounded-3 shadow-sm" style={{ border: '2px solid #B59BCD' }}>
                        <img
                            src="./assest/simg2.jpg"
                            alt="Hospitality"
                            className="w-100 mb-3 rounded-3"
                            style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
                        />
                        <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
                            Hospitality
                        </h5>
                        <Button variant="Link" className="d-block mx-auto" style={{ fontSize: '1.3rem' }}>
                            Go to Page &gt;
                        </Button>
                    </div>
                </SwiperSlide>

                {/* Card 7 */}
                <SwiperSlide>
                    <div className="card-item p-3 bg-white rounded-3 shadow-sm" style={{ border: '2px solid #B59BCD' }}>
                        <img
                            src="./assest/simg3.jpg"
                            alt="Professional"
                            className="w-100 mb-3 rounded-3"
                            style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
                        />
                        <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
                            Professional
                        </h5>
                        <Button variant="Link" className="d-block mx-auto" style={{ fontSize: '1.3rem' }}>
                            Go to Page &gt;
                        </Button>
                    </div>
                </SwiperSlide>

                {/* Card 8 */}
                <SwiperSlide>
                    <div className="card-item p-3 bg-white rounded-3 shadow-sm" style={{ border: '2px solid #B59BCD' }}>
                        <img
                            src="./assest/simg4.jpg"
                            alt="Consultant"
                            className="w-100 mb-3 rounded-3"
                            style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
                        />
                        <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
                            Consultant
                        </h5>
                        <Button variant="Link" className="d-block mx-auto" style={{ fontSize: '1.3rem' }}>
                            Go to Page &gt;
                        </Button>
                    </div>
                </SwiperSlide>

                {/* Card 9 */}
                <SwiperSlide>
                    <div className="card-item p-3 bg-white rounded-3 shadow-sm" style={{ border: '2px solid #B59BCD' }}>
                        <img
                            src="./assest/simg1.jpg"
                            alt="Startups"
                            className="w-100 mb-3 rounded-3"
                            style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
                        />
                        <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
                            Startups
                        </h5>
                        <Button variant="Link" className="d-block mx-auto" style={{ fontSize: '1.3rem' }}>
                            Go to Page &gt;
                        </Button>
                    </div>
                </SwiperSlide>

                {/* Card 10 */}
                <SwiperSlide>
                    <div className="card-item p-3 bg-white rounded-3 shadow-sm" style={{ border: '2px solid #B59BCD' }}>
                        <img
                            src="./assest/simg2.jpg"
                            alt="Retail"
                            className="w-100 mb-3 rounded-3"
                            style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
                        />
                        <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
                            Retail
                        </h5>
                        <Button variant="Link" className="d-block mx-auto" style={{ fontSize: '1.3rem' }}>
                            Go to Page &gt;
                        </Button>
                    </div>
                </SwiperSlide>

                {/* Card 11 */}
                <SwiperSlide>
                    <div className="card-item p-3 bg-white rounded-3 shadow-sm" style={{ border: '2px solid #B59BCD' }}>
                        <img
                            src="./assest/simg3.jpg"
                            alt="Manufacturing"
                            className="w-100 mb-3 rounded-3"
                            style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
                        />
                        <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
                            Manufacturing
                        </h5>
                        <Button variant="Link" className="d-block mx-auto" style={{ fontSize: '1.3rem' }}>
                            Go to Page &gt;
                        </Button>
                    </div>
                </SwiperSlide>

                {/* Card 12 */}
                <SwiperSlide>
                    <div className="card-item p-3 bg-white rounded-3 shadow-sm" style={{ border: '2px solid #B59BCD' }}>
                        <img
                            src="./assest/simg4.jpg"
                            alt="E-commerce"
                            className="w-100 mb-3 rounded-3"
                            style={{ objectFit: 'cover', aspectRatio: '19 / 9' }}
                        />
                        <h5 className="text-center mb-3" style={{ fontSize: '1.9rem' }}>
                            E-commerce
                        </h5>
                        <Button variant="Link" className="d-block mx-auto" style={{ fontSize: '1.3rem' }}>
                            Go to Page &gt;
                        </Button>
                    </div>
                </SwiperSlide>
            </Swiper>
        </Container>
    );
};

export default IndustryTypes;
