import React from 'react';

const PrivacyPolicyPage = () => {
    return (
        <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto', marginTop: '100px' }}>
            <h1 className='fw-bold' style={{ fontSize: '2.5rem', textAlign: 'center' }}>
                Privacy Policy
            </h1>
            <p style={{ fontSize: '1.2rem', marginTop: '20px' }}>
                <h4> WE AT MyPe VALUE YOUR TRUST & RESPECT YOUR PRIVACY</h4>
                <br></br>
                This privacy policy (“Privacy Policy”) provides You with details about the manner in which Your data is collected, stored &
                used by Us. You are advised to read this Privacy Policy carefully. By registering on the Platform (as defined below), using
                any medium: mobile app, website, web app, mobile site, You permit Us to use & disclose your Personal Information (defined below)
                in accordance with this Privacy Policy by providing Your consent to this Privacy Policy. If You do not agree to the terms of the
                policy, please do not use or access the Platform.
                <br /><br />
                “Personal Information” of a person means any information that relates to a natural person, which, either directly or indirectly, in combination with other information available or likely to be available with Us, is capable of identifying such person under the applicable laws in India.
                <br /><br />
                Personal Information that is freely available, accessible in the public domain, or available under the Right to Information Act, 2005, is excluded from the scope of this definition.
                <br /><br />
                Note: Our Privacy Policy may change at any time without prior notification. To make sure that You are aware of any changes, kindly review this Privacy Policy periodically. This Privacy Policy shall apply uniformly to https://MyPe.money/ (“Platform”).
                <br /><br />
                Throughout this Privacy Policy, the capitalized terms "MyPe" "We", "Us" and "Our" refers to MyPe Financial Technologies Private Limited,
                its affiliates and assigns, and the terms “User”, “You”, “Your”, and “Yourself” refer to the users of the Platform whose Personal
                Information is being collected in accordance with the terms of this Privacy Policy. MyPe is providing this Privacy Policy to
                familiarize you with:
                <br /><br />

                <strong>THE SCOPE OF THIS PRIVACY POLICY AND WHO IT APPLIES TO</strong>
                <br /><br />

                <li>This Privacy Policy applies to all forms of digital Personal Information.</li>  
                   <li>We handle data in our own right and also for and on behalf of the Users.</li>
                <li>  If, at any time, a User provides data or other information about someone other than himself, herself or itself, the User warrants that it has that person’s consent to provide such information for the purpose specified.</li>
           
                <li>  The Platform should not be used or accessed by minors (persons under the age of 18 years).</li>
                <li>   By registering on the Platform, You are accepting this Privacy Policy. This Privacy Policy is incorporated into and
                subject to the terms of use governing access and use of the Platform (hosted at https://MyPe.money/ ) (“Terms of Use”).</li>
       
               <br></br>
            
                <strong>DEVICE PERMISSIONS</strong>
                <br /><br />
                We collect information from and about the web-connected devices you use (“Device”) that integrates with our Platform,
                by accessing and using the Platform, You hereby authorize the Platform with:
                <br /><br />
                <ul>
                    <li><strong>Permission to access the Device's camera:</strong> The Platform allows You to scan and images/documents with optical character recognition instead of requiring you to manually enter the data or information required to avail the Platform services, in order to enhance your user experience. To facilitate the provision of these services by MyPe to the User, the Platform requires access to the Device's camera. The User consents to the access of the Device camera for the facilitation of products/services provided through the Platform.</li>
                    <li><strong>Permission to read/copy contacts from the Device:</strong> The Platform, inter-alia, enables you to create invoices, purchase orders for your vendors and customers and as a means to improve your experience, the Platform allows you to automatically add contacts from your Device's phonebook without compelling You to manually enter these details in every instance. To facilitate the provision of these services by MyPe to the User, the Platform may require access to the contact-book on your Device. The User consents to the access of the contact-book on the Device for facilitation of products/services through the Platform.</li>
                    <li><strong>Permission to store on the Device:</strong> The Platform allows you to store sales and purchase orders, reports, images, invoice pdfs etc. that are generated from the Platform on your Device, for the purpose of offline viewing. To facilitate the provision of these services by MyPe to the User, the Platform may store information locally on the Device of the User. The User consents to the local storage of information on the Device of the User for the provision of the products/services through the Platform.</li>
                    <li><strong>Permission to access Device location:</strong> As a security measure and means to tackle fraud, the Platform accesses your Device location to validate banking transactions initiated using the Platform. To facilitate the provision of these services by MyPe to the User, the Platform may access the Device’s location. The User consents to access the Device location for the facilitation of products/services by MyPe through the Platform.</li>
                </ul>
                <br />
                <strong>INFORMATION COLLECTED</strong>
                <br /><br />
                When You use the Platform, We collect and store Your Personal Information which is provided by You from time to time. This allows us to provide services and features that most likely meet your needs, and to customize the Platform to meet your requirements and make Your experience safer and easier. We collect Personal Information from You that we consider necessary for achieving this purpose. We further process Your Personal Information for marketing and any other digital purpose to enhance Your user experience and to provide You better service over the Platform.

                MyPe respects the privacy of its Users and is committed to protect such privacy as detailed herein. You may encounter "Cookies" or other similar device on a web page. These are data collection devices to help analyze Our web flow and measure promotional effectiveness. "Cookies" are small files placed on your hard drive that assist MyPe in providing services to You and giving you information targeted to Your interests. Most cookies are session cookies, meaning that they are automatically deleted from Your Device at the end of a session. You are always free to decline a cookie if Your browser permits.

                Once you create an account (register) on the Platform and give us Your Personal Information, You are not anonymous to Us.
                 Wherever possible, We indicate which fields are required and which fields are optional. If You are unable to provide the
                  information or consent required by Us, We may not be able to provide You with the product or service You have requested.

<br></br>
<br></br>
<strong>INFORMATION THAT WE COLLECT FROM YOU WOULD INCLUDE :</strong>
              
                <p>In order to determine Your eligibility and enable the products/services offered on the Platform for You, We collect and process
                     certain categories of Personal Information belonging to You, including the following:</p>

                <ul>


                    <li>Personal information like date of birth, age, marital status, birthplace, nationality, mother tongue.</li>
                    <li>Contact information (e.g., name, address, telephone, and email address)..</li>
                    <li>Government-issued identifiers, including PAN and GST information;</li>
                    <li>Financial information, including bank account details, billing information, tax information and payroll information (as applicable);</li>
                    <li>Photographs and signature copies;</li>
                    <li>Information which would help determine Your eligibility to avail the services offered on the Platform; and
                        Any other information that MyPe is required to collect as per specific mandate from any bank, NBFC or as a requirement under applicable law, regulation, rule or statute in India.</li>
                </ul>
                <br />
                <strong>INFORMATION SHARING AND DISCLOSURE TO THIRD PARTIES</strong>
                <br /><br />
                The disclosure of Your information may be required by Us to provide You access to Our services, to comply with Our legal obligations, to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to Our services. We do not disclose Your
                Personal Information to third parties except in accordance with the terms contained herein.
                <br /><br />
              
                We may disclose Personal Information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process.
                <br /><br />
              
                We may disclose Personal Information to law enforcement offices, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce the Terms of Use or this Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of Our users or the general public.
                <br /><br />
              
                We may disclose Personal Information to third parties who work on Our behalf, provided that such third parties adhere to the confidentiality obligations consistent with this Privacy Policy. These third parties are authorized to use Your Personal Information only as necessary to provide the Platform services requested or availed by You. These services may include providing cloud computing infrastructure, Platform crash reporting,
                Platform usage pattern so that we can serve You better and providing customer support or account administration.
                <br /><br />


                <strong>DATA RETENTION</strong>
                <br /><br />
                We will retain your information for as long as Your account is active or as needed to provide the Platform services. We shall retain and use the information collected by Us as necessary to comply with our legal obligations, resolve disputes or for other business purposes. If You cancel/ deactivate/ unsubscribe Your account with Us, We are not under any obligation to retain Your information, except for the purposes specified above. Without prejudice, We may retain Your information after You cancel/ deactivate/ unsubscribe Your account with Us for such period as may be required to ensure compliance with applicable laws.
                <br /><br />
                <strong>INFORMATION SECURITY</strong>
                <br /><br />
                You are aware of all security risks, including possible third-party interception of any of Your transactions and statements of account on the internet and the content of Your transactions or statements of account becoming known to third parties. You agree and undertake that you shall not hold MyPe liable therefore in any way. The use and storage of any information, including without limitation, the credit card number, PIN, password, account information, transaction activity, account balances and any other information available or stored on Your Device is at Your own risk and responsibility.
                <br /><br />
                <strong>YOUR RIGHTS IN RELATION TO SENSITIVE PERSONAL DATA OR INFORMATION COLLECTED BY US</strong>
                <br /><br />
                You have the right to withdraw your consent at any time in writing by sending an e-mail to us at letstalk@bankMyPe.co in accordance with the terms of this Privacy Policy. However, please note that withdrawal of consent will not be retrospective in nature and shall be applicable prospectively. You may write to us at letstalk@bankMyPe.co to access, review, modify or correct your personal data or information or withdraw your consent to provide personal data or information. We are not responsible for the authenticity of the information provided by You. You agree and acknowledge that Your right to access the Platform shall be impacted or may be ceased, depends upon Your request to access, review, modify or correct your personal data or information or withdraw Your consent to provide sensitive personal data or information.
                <br /><br />
                <strong>LINKS TO OTHER SITES</strong>
                <br /><br />
                Links to third-party advertisements, third-party websites or any third party electronic communication service which are operated
                by third parties may be provided on the Platform. Such links may not be controlled by, or affiliated to, or associated with,
                MyPe unless otherwise expressly specified on the Platform.<br></br>

                MyPe is not responsible for any form of transmission, whatsoever, received by You from any third party website. Accordingly, MyPe does not make any representations concerning the privacy practices or policies of such third parties or terms of use of such third party websites, nor does MyPe control or guarantee the accuracy, integrity, or quality of the information, data, text, software, music, sound, photographs, graphics, videos, messages or other materials available on such third party websites. The inclusion or exclusion does not imply any endorsement by MyPe of the third party websites, the website's provider, or the information on the third party website. The information provided by You to such third party websites shall be governed in accordance with the privacy policies of such third party websites, and it is recommended that You review the privacy policy of such third party websites prior to using such websites.
                <br /><br />
                <strong>ACKNOWLEDGEMENTS</strong>
                <br /><br />
               
            You hereby agree that the application and copies of all documents submitted by You, to the extent permitted under applicable law, are and shall remain the property of MyPe, and MyPe shall not be obliged to return the same to you upon Your request. Any unauthorized access to the Platform is a breach of these terms and a violation of the law. You agree not to access the Platform by any means other than through the interface that is provided by MyPe.
            <br></br>You hereby acknowledge and agree that this Privacy Policy:
            <ul>
                <li>is clear and easily accessible and provide statements of policies and practices MyPe with respect to information security;</li>
                <li>provides for the various types of personal or sensitive personal data of information to be collected;</li>
                <li>provides for the purposes of collection and usage of the information;</li>
                <li>provides for disclosure of information; and</li>
                <li>provides for reasonable security practices and procedures.</li>
            
            </ul>
            
            </p>
        </div>
    );
};

export default PrivacyPolicyPage;
