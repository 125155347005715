import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import SignUpPage from './SignUpPage';
import { useParams } from 'react-router-dom';
const FormField = ({
    controlId,
    label,
    type,
    placeholder,
    value,
    onChange,
    isInvalid,
    errorMessage,
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const hasValue = value && value.trim().length > 0;

    return (
        <Form.Group controlId={controlId} className="mb-4 position-relative">
            <Form.Label
                style={{
                    fontSize: '1rem',
                    position: 'absolute',
                    top: hasValue || isFocused ? '-10px' : '12px',
                    left: '10px',
                    color: hasValue || isFocused ? '#752abf' : '#6c757d',
                    transition: 'all 0.2s ease-in-out',
                    background: hasValue || isFocused ? '#fff' : 'transparent',
                    padding: hasValue || isFocused ? '0 5px' : '0',
                    zIndex: 1,
                }}
            >
                {label}
            </Form.Label>
            <div className="position-relative">
                <Form.Control
                    type={type === 'password' && isPasswordVisible ? 'text' : type}
                    placeholder={hasValue || isFocused ? '' : placeholder}
                    value={value}
                    onChange={onChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    isInvalid={isInvalid}
                    style={{
                        padding: '15px 40px 15px 10px',
                        fontSize: '1.1rem',
                        border: isInvalid ? '1px solid red' : '1px solid #B59BCD',
                        borderRadius: '5px',
                    }}
                />
                {type === 'password' && (
                    <span
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                            color: '#6c757d',
                        }}
                    >
                        {isPasswordVisible ? <FaEyeSlash size={18} /> : <FaEye size={18} />}
                    </span>
                )}
            </div>
            {isInvalid && (
                <Form.Text className="text-danger" style={{ fontSize: '0.9rem' }}>
                    {errorMessage}
                </Form.Text>
            )}
        </Form.Group>
    );
};

const LoginPage = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.email) newErrors.email = 'User ID is required.';
        if (!formData.password) newErrors.password = 'Password is required.';
        return newErrors;
    };

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = validate();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            alert('Logged in successfully!');
            // Redirect to Dashboard or Home Page
            navigate("/dashboard");
            setErrors({});
        }
    };
    const [show, setshow] = useState(true)
    const { id } = useParams();

    useEffect(() => {
        if (id === "singup") {
            setshow(false);
        }
    }, [id]);
    return (
        <div>
            {
                show ?

                    <div
                        style={{
                            maxWidth: '600px',
                            margin: 'auto',
                            marginTop: '150px',
                            padding: '40px',
                            border: '1px solid #B59BCD',
                            borderRadius: '10px',
                            backgroundColor: '#fff',
                            boxShadow: '0 4px 10px #B59BCD',
                        }}
                    >
                        <h2 className="text-center fw-bold" style={{ fontSize: '2.8rem' }}>
                            Login
                        </h2>
                        <p className="text-center" style={{ fontSize: '1.2rem' }}>
                            Welcome back! Please log in to your account.
                        </p>
                        <Form onSubmit={handleSubmit}>
                            <FormField
                                controlId="email"
                                label="User ID*"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                                isInvalid={!!errors.email}
                                errorMessage={errors.email}
                            />
                            <FormField
                                controlId="password"
                                label="Your Password*"
                                type="password"
                                value={formData.password}
                                onChange={handleChange}
                                isInvalid={!!errors.password}
                                errorMessage={errors.password}
                            />

                            <div className="text-end mb-4">
                                <Link to="/forgot-password" style={{ fontSize: '1.1rem', color: '#752abf' }}>
                                    Forgot Password?
                                </Link>
                            </div>

                            <Button
                                style={{
                                    background: '#752abf',
                                    borderRadius: '1.5rem',
                                    width: 'fit-content',
                                    border: 'none',
                                }}
                                type="submit"
                                className="w-100 mb-4"
                            >
                                Login
                            </Button>

                            <div className="text-center">
                                <p style={{ fontSize: '1.1rem' }}>
                                    Don't have an account?{' '}
                                    <p style={{ color: '#752abf' }} onClick={() => setshow(false)}>
                                        Sign Up
                                    </p>
                                </p>
                            </div>
                        </Form>
                    </div>
                    :
                    <SignUpPage setshow={setshow} />
            }


        </div>





    );
};

export default LoginPage;
