
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const Partners = () => {
    const logos = [
        "https://open.money/_app/immutable/assets/koo-logo.RDidyyX2.svg",
        "https://open.money/_app/immutable/assets/inshorts-logo.8qUfqJY0.svg",
        "https://open.money/_app/immutable/assets/driveu-logo.fwaWPk2n.svg",
        "https://open.money/_app/immutable/assets/anthyesti-logo.uzzn1Mvj.svg",
        "https://open.money/_app/immutable/assets/kidzon-logo.Zxw6HdVR.svg",
        "https://open.money/_app/immutable/assets/bizurukul-logo.LDXhzTKu.svg"
    ];

    const extendedLogos = [...logos, ...logos];

    const [shift, setShift] = useState(0);
    const [isPaused, setIsPaused] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!isPaused) {
                setShift((prevShift) => {
                    // Reset shift when we reach halfway (looping point)
                    if (Math.abs(prevShift) >= logos.length * 100) {
                        return 0;
                    }
                    return prevShift - 1;
                });
            }
        }, 20); // Speed up the interval for a smoother animation

        return () => clearInterval(intervalId);
    }, [isPaused, logos.length]);

    const handleMouseEnter = () => {
        setIsPaused(true);
    };

    const handleMouseLeave = () => {
        setIsPaused(false);
    };

    return (
        <Container id="ourclients" className="text-center mt-0">
            <Row className="d-flex align-items-center justify-content-center">
                <div
                    className="d-flex overflow-hidden"
                    style={{ width: '100%' }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <div
                        className="d-flex"
                        style={{
                            transform: `translateX(${shift}px)`,
                            transition: 'transform 0.02s linear',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        {extendedLogos.map((logo, index) => (
                            <Col key={index} xs="auto" className="p-4 d-flex align-items-center justify-content-center">
                                <img src={logo} alt={`Logo ${index + 1}`} className="img-fluid" height="30" />
                            </Col>
                        ))}
                    </div>
                </div>
            </Row>
        </Container>
    );
};

export default Partners;

