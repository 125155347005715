// import React from 'react';
// import { Container, Row, Col, ListGroup } from 'react-bootstrap';
// import { BiCheckCircle } from 'react-icons/bi'; 

// function PaymentsPage() {
//   return (
// <div className="d-flex">
// <Container style={{ marginLeft: '115px', alignItems: 'flex-start' }} className="mt-5">
//       <Row>
//         <Col>
//           <h6 className=" mb-4 mt-5" style={{ color:'#752abf', fontSize: '1.1rem' }}>PAY VENDORS</h6>
//           <h2 className="mb-4" style={{ fontWeight: 'bold', fontSize:'2.5rem'}}>Make Payments Easier, With <br></br> More Control</h2>
//              <p className="text-muted mb-3" style={{fontSize: '1.2rem' }}>  Pay, manage and reconcile all your bills on one single <br></br>platform.</p>
//           <ListGroup variant="flush">
//             <ListGroup.Item className="border-0">
//               <div className="d-flex align-items-start">
//                 <BiCheckCircle className="text-success me-3 mt-1" size={24} /> 
//                 <p className="text-muted mb-0" style={{fontSize: '1.2rem' }}>Upload bills on the go.</p>
//               </div>
//             </ListGroup.Item>
//             <ListGroup.Item className="border-0">
//               <div className="d-flex align-items-start">
//                 <BiCheckCircle className="text-success me-3 mt-1" size={25} />
//                 <p className="text-muted mb-0" style={{fontSize: '1.2rem' }}>Review and track payables on one dashboard</p>
//               </div>
//             </ListGroup.Item>
//             <ListGroup.Item className="border-0">
//               <div className="d-flex align-items-start">
//                 <BiCheckCircle className="text-success me-3 mt-1" size={25} />
//                 <p className="text-muted mb-0" style={{fontSize: '1.2rem' }}>Make direct account-to-account payments.</p>
//               </div>
//             </ListGroup.Item>
//             <ListGroup.Item className="border-0">
//               <div className="d-flex align-items-start">
//                 <BiCheckCircle className="text-success me-3 mt-1" size={25} />
//                 <p className="text-muted mb-1" style={{fontSize: '1.2rem' }}>Auto-sync bills with your accounting software in minutes.</p>
//               </div>
//             </ListGroup.Item>
//           </ListGroup>
//         </Col>
//       </Row>
//     </Container>

//     <Container className="my-5 py-5" style={{ overflow: 'hidden' }}>
//         <Row>
//           <Col className="d-flex justify-content-center mb-5">
//             <video
//               src="./assest/animation3.mp4"
//               style={{
//                 width: '100%',
//                 maxWidth: '400px',
//                 height: '450px',
//                 objectFit: 'cover', 
//               }}
//               autoPlay
//               muted
//               loop
//             />
//           </Col>
//         </Row>
//       </Container>
// </div>
//   );
// }
// export default PaymentsPage;


import React from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { BiCheckCircle } from 'react-icons/bi';

function PaymentsPage() {
  return (
    <div className='d-flex'>
      {/* Main Content Container */}
      <Container className="mt-5">
        <Row>
          <Col xs={12} md={8} lg={6} className="mb-4">
            {/* Heading Section */}
            <h6 className="mb-4 mt-5" style={{ color: '#752abf', fontSize: '1.1rem' }}>PAY VENDORS</h6>
            <h2 className="mb-4 fs-3 fs-md-4 fs-lg-5" style={{ fontWeight: 'bold' }}>
              Make Payments Easier, With <br /> More Control
            </h2>
            <p className="text-muted mb-3" style={{ fontSize: '1.2rem' }}>
              Pay, manage and reconcile all your bills on one single platform.
            </p>

            {/* List Group */}
            <ListGroup variant="flush">
              <ListGroup.Item className="border-0">
                <div className="d-flex align-items-start">
                  <BiCheckCircle className="text-success me-3 mt-1" size={24} />
                  <p className="text-muted mb-0" style={{ fontSize: '1.2rem' }}>Upload bills on the go.</p>
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="border-0">
                <div className="d-flex align-items-start">
                  <BiCheckCircle className="text-success me-3 mt-1" size={25} />
                  <p className="text-muted mb-0" style={{ fontSize: '1.2rem' }}>Review and track payables on one dashboard</p>
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="border-0">
                <div className="d-flex align-items-start">
                  <BiCheckCircle className="text-success me-3 mt-1" size={25} />
                  <p className="text-muted mb-0" style={{ fontSize: '1.2rem' }}>Make direct account-to-account payments.</p>
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="border-0">
                <div className="d-flex align-items-start">
                  <BiCheckCircle className="text-success me-3 mt-1" size={25} />
                  <p className="text-muted mb-1" style={{ fontSize: '1.2rem' }}>Auto-sync bills with your accounting software in minutes.</p>
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Col>



          <Col xs={12} md={8} lg={6} className="d-flex justify-content-center mb-5">
            <video
              src="./assest/animation3.mp4"
              style={{
                width: '100%',
                maxWidth: '500px',
                height: 'auto',
                objectFit: 'cover',
              }}
              autoPlay
              muted
              loop
            />
          </Col>
        </Row>
      </Container>

    </div>
  );
}

export default PaymentsPage;
