// HowItWorks.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaFileInvoice, FaPaperPlane, FaCheckSquare } from 'react-icons/fa';

const Hwork = () => {
  return (
    <Container className="my-5 p-4 bg-light rounded">
      <h2 className="mb-4 fw-bold">How It Works</h2>
      <Row className="text-center">
        <Col md={4}>
          <Card className="border-0 bg-light mb-5">
            <Card.Body>
              <FaFileInvoice size={50} style={{ color: '#6e4bd9' }} className="mb-3" />
              <Card.Title className="fw-bold">Create Invoice</Card.Title>
              <Card.Text>
                Send customers a professional, GST-compliant invoice via email, SMS, and WhatsApp.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="border-0 bg-light">
            <Card.Body>
              <FaPaperPlane size={50} style={{ color: '#6e4bd9' }} className="mb-3" />
              <Card.Title className="fw-bold">Track Receivables</Card.Title>
              <Card.Text>
                Track invoice status and send reminders to customers.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="border-0 bg-light">
            <Card.Body>
              <FaCheckSquare size={50} style={{ color: '#6e4bd9' }} className="mb-3" />
              <Card.Title className="fw-bold">Get Paid</Card.Title>
              <Card.Text>
                Track received payments on one dashboard with automated reconciliation and settlement.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>

    // <NavDropdown.Item href="#electricity">Electricity</NavDropdown.Item>
    //           <NavDropdown.Item href="#gas-piped">Gas-Piped</NavDropdown.Item>
    //           <NavDropdown.Item href="#lpg-gas-line">LPG Gas line</NavDropdown.Item>
    //           <NavDropdown.Item href="#water">Water</NavDropdown.Item>
    //           <NavDropdown.Item href="#municipal-taxes">Municipal Taxes & Services</NavDropdown.Item>
    //           <NavDropdown.Item href="#mobile-postpaid">Mobile Postpaid / Landline Postpaid</NavDropdown.Item>
    //           <NavDropdown.Item href="#mobile-prepaid">Mobile Prepaid Recharges</NavDropdown.Item>
    //           <NavDropdown.Item href="#broadband">Broadband / Datacard</NavDropdown.Item>
    //           <NavDropdown.Item href="#cable">Cable</NavDropdown.Item>
    //           <NavDropdown.Item href="#dth">DTH</NavDropdown.Item>

  );
};

export default Hwork;
