import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const BusinessPaymentAutomation = () => {
  const data = [
    {
      title: "80%",
      description:
        "80% reduction in manual payment tasks through automation and our easy-to-use software",
    },
    {
      title: "500 hrs",
      description:
        "Save on average 500 hrs every year by using MyPe to reconcile your accounts payables and receivables",
    },
    {
      title: "25%",
      description:
        "25% reduction in reconciliation efforts with our real-time two-way accounting software sync",
    },
  ];

  return (
    <Container className="text-center py-5 my-5">
         <h2 className="mb-5 fw-bold fs-3 fs-md-4 fs-lg-5">
        We Make Business Payment <br /> Automation A Reality
      </h2>
      <Row className="mt-4">
        {data.map((item, index) => (
          <Col key={index} md={4} className="d-flex justify-content-center">
            <Card className="text-center" style={{ width: "18rem", border: "none" }}>
              <Card.Body>
                <Card.Title className="display-4 fw-bold" style={{ color: '#752abf', fontSize: '3.1rem' }}>{item.title}</Card.Title>
                <Card.Text className="text-muted mb-5">{item.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default BusinessPaymentAutomation;


