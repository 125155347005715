import React from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { BiCheckCircle } from 'react-icons/bi'; // Importing the check icon

function BusinessPaymentsInfo() {
  return (
    <div className="d-flex">
      <Container className="mt-5">
        <Row>
          <Col xs={12} md={6}>
            <h6 className="mb-4" style={{ color: '#752abf', fontSize: '1.1rem' }}>ALL-IN-ONE SOLUTION</h6>
            <h2 className="mb-4 fs-3 fs-md-4 fs-lg-5" style={{ fontWeight: 'bold' }}>
              Simplify your business <br /> payments with MyPe
            </h2>
            <p className="text-muted mb-4" style={{ fontSize: '1.2rem' }}>
              Streamline your business payments with MyPe. Say goodbye <br /> to logging into multiple banking platforms to track your <br /> receivables and payables.
            </p>
            <ListGroup variant="flush">
              <ListGroup.Item className="border-0 px-0 pb-3">
                <div className="d-flex">
                  <BiCheckCircle className="text-success me-3 mt-2" size={25} />
                  <div>
                    <p className="mb-0" style={{ fontSize: '1.2rem', color: '#292929' }}>Connect all your bank accounts</p>
                    <p className="text-muted mb-0" style={{ fontSize: '1.2rem' }}>
                      Connect your current accounts to MyPe to easily send <br /> and receive payments.
                    </p>
                  </div>
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="border-0 px-0 pb-3">
                <div className="d-flex">
                  <BiCheckCircle className="text-success me-3 mt-2" size={25} />
                  <div>
                    <p className="mb-1 mt-0" style={{ fontSize: '1.2rem', color: '#292929' }}>Create bills and invoices</p>
                    <p className="text-muted mb-0" style={{ fontSize: '1.2rem' }}>
                      Create bills and invoices on MyPe or import them from <br /> your accounting software.
                    </p>
                  </div>
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="border-0 px-0">
                <div className="d-flex">
                  <BiCheckCircle className="text-success me-3 mt-2" size={25} />
                  <div>
                    <p className="mb-1 mt-0" style={{ fontSize: '1.2rem', color: '#292929' }}>Reconcile payments automatically</p>
                    <p className="text-muted" style={{ fontSize: '1.2rem' }}>
                      Pay bills or get paid using your connected bank accounts <br /> and reconcile payments with your accounting software.
                    </p>
                  </div>
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Col>

          <Col xs={12} md={6} className="d-flex justify-content-center">
            <img
              src="./assest/np2aa.png"
              alt="Illustrative visual"
              className="img-fluid"
              style={{
                maxWidth: '100%',
                height: 'auto',
                objectFit: 'contain',
                marginTop: '150px',
              }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default BusinessPaymentsInfo;
