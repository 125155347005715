import React from 'react'
import HeroSection from './components/HeroSection';
import Mpay from './components/Mpay';
import VideoSection from './components/VideoSection';
import Partners from './components/partners';
import SecuritySection from './components/SecuritySection';
import BusinessPayments from './components/BusinessPayments';
import PaymentsPage from './components/PaymentsPage';
import Payments from './components/Payments';
import ConnectBanking from './components/ConnectBanking';
import GetPaid from './components/GetPaid';
import Hwork from './components/Hwork';
import Statistics from './components/Statistics';
import IndustryTypes from './components/IndustryTypes';
import BusinessPaymentAutomation from './components/BusinessPaymentAutomation';
import Footer from './components/Footer';
import CustomNavbar from './components/CustomNavbar';



function home() {
  return (
    <div>
       <CustomNavbar/>
      <HeroSection />
      <Mpay />
    <VideoSection />
      <Partners />
      <SecuritySection />
      <BusinessPayments />
      <PaymentsPage />
      <Payments />
      <ConnectBanking />
      <GetPaid />
      <Hwork />
      <Statistics />
      <IndustryTypes />
      <BusinessPaymentAutomation />
      <Footer />
    </div>
  )
}

export default home
