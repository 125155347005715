import React from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { BiCheckCircle } from 'react-icons/bi';

function ConnectBanking() {
  return (
    <Container fluid className="my-3 py-3">
      <Row className="d-flex justify-content-center align-items-center">
        {/* Video Column */}
        <Col xs={12} md={6} className="d-flex justify-content-center mb-5 mb-md-0">
          <video
            src="./assest/animation4.mp4"
            className="w-100"
            style={{
              maxWidth: '400px',
              height: '450px',
              objectFit: 'cover',
            }}
            autoPlay
            muted
            loop
          />
        </Col>

        {/* Text Content Column */}
        <Col xs={12} md={6}>
          <h6 className="mb-4 mt-5" style={{ color: '#752abf', fontSize: '1.1rem' }}>
            CONNECTED BANKING
          </h6>
          <h2 className="mb-4 fw-bold">Get A Complete View Of Your<br /> Cashflow</h2>
          <p className="text-muted mb-3">
            Manage all your current accounts in one place and get an end-to-end overview across
            banking, payments, and accounting.
          </p>
          <ListGroup variant="flush">
            {/* List Items */}
            {[
              'Secure and direct account-to-account payments',
              'Digital dashboard for account balances, payables, and receivables',
              'Simpler beneficiary management',
            ].map((text, index) => (
              <ListGroup.Item key={index} className="border-0 px-0">
                <div className="d-flex align-items-center gap-3">
                  {/* Consistent Icon Size */}
                  <div
                    style={{
                      flexShrink: 0,
                      width: '24px',
                      height: '24px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <BiCheckCircle className="text-success" style={{ fontSize: '24px' }} />
                  </div>
                  {/* Text */}
                  <p className="text-muted mb-0" style={{ lineHeight: '1.5', margin: 0 }}>
                    {text}
                  </p>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
}

export default ConnectBanking;
