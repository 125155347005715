import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function SecuritySection() {
    return (
        <Container  className="py-3 bg-light mt-0">
            <Row className="justify-content-center ">
                
                <Col xs={12} md={4} className="text-center mb-5 mb-md-0 border-top border-bottom">
                    <h5 className="text-secondary mt-5">Backed by</h5>
                    <div className="d-flex justify-content-center align-items-center gap-4 mb-5">
                        <Image src="./assest/googlelogo.svg" alt="Google Logo" height="25" />
                        <Image src="./assest/visalogo.svg" alt="Visa Logo" height="20" />
                    </div>
                </Col>
                
                <Col xs={12} md={4} className="text-center mb-5 mb-md-0 border-top border-bottom">
                    <h5 className="text-secondary  mt-5 ">Secure & compliant</h5>
                    <div className="d-flex justify-content-center align-items-center gap-4 mb-5">
                        <Image src="./assest/goldlogo.svg" alt="Gold Seal Logo" height="30" />
                        <Image src="./assest/pcidsslogo.svg" alt="PCI DSS Compliant Logo" height="30" />
                        <Image src="./assest/isoiconlogo.jpg" alt="ISO Certification Logo" height="35" />
                        <Image src="./assest/soclogo.jpg" alt="AICPA SOC Logo" height="30" />
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default SecuritySection;
