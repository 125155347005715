
// import React from 'react';
// import { Container, Row, Col } from 'react-bootstrap';
// import 'bootstrap-icons/font/bootstrap-icons.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

// function Footer() {
//   return (
//     <div style={{ backgroundColor: '#001A35', color: '#FFFFFF', padding: '50px 20px' }}>
//       <Container>
//         <Row>
//           <Col xs={12} sm={6} md={3} className="mb-4">
//             <h5>Open</h5>
//             <p style={{ fontSize: '0.9rem', lineHeight: '1.5' }}>
//               Open is a connected banking platform for business payments that simplifies the process of sending, receiving,
//               and reconciling payments for businesses.
//             </p>
//             <p style={{ fontSize: '0.9rem', lineHeight: '1.5' }}>
//               With Open, businesses can connect their bank accounts and accounting software like Tally, Zoho Books
//               and more, to send and receive payments and reconcile their payment transactions.
//             </p>
//             <p style={{ fontSize: '0.9rem', lineHeight: '1.5' }}>
//               In addition to making payments and reconciliation easy, Open also offers built-in tools for GST invoicing,
//               payment links for collecting online payments, and the ability to track account payables and receivables.
//               Open has partnered with leading banks like ICICI Bank, State Bank of India, Axis Bank, Yes Bank, and other
//               major Indian banks for connected banking, allowing businesses to directly make payments from the Open dashboard.
//               But that's not all - Open also offers additional business tools for managing end-to-end payroll, expense management,
//               and GST & tax filing directly from the platform.
//             </p>
//           </Col>

//           <Col xs={12} sm={6} md={3} className="mb-4">
//             <h6>Get Started</h6>
//             <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
//               <li>Login</li>
//               <li>Sign Up</li>
//             </ul>

//             <h6 className="mt-4">Products</h6>
//             <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
//               <li>Pay</li>
//               <li>Get Paid</li>
//               <li>Spend Management</li>
//               <li>Connected Banking</li>
//             </ul>
//             <h6>Connected Banking</h6>
//             <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
//               <li>ICICI Connected Banking</li>
//               <li>SBI Connected Banking</li>
//               <li>Axis Bank Connected Banking</li>
//               <li>Yes Bank Connected Banking</li>
//             </ul>

//             <h6 className="mt-4">Accounting Automation</h6>
//             <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
//               <li>Accounts Payable Automation</li>
//               <li>Accounts Receivable Automation</li>
//               <li>e-Invoicing</li>
//             </ul>
//           </Col>

//           <Col xs={12} sm={6} md={3} className="mb-4">
//             <h6>Pricing</h6>
//             <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
//               <li>Get started for free</li>
//             </ul>

//             <h6 className="mt-4">Business Tools</h6>
//             <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
//               <li>Open Payroll</li>
//               <li>Open Accounting</li>
//               <li>GST Returns</li>
//             </ul>

//             <h6 className="mt-4">Integration & Sync</h6>
//             <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
//               <li>Tally</li>
//               <li>Zoho Books</li>
//               <li>Oracle NetSuite</li>
//               <li>Microsoft Dynamics</li>
//             </ul>

//             <h6 className="mt-4">Solutions</h6>
//             <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
//               <li>Small Business</li>
//               <li>Startups</li>
//               <li>SMEs</li>
//               <li>Enterprises</li>
//               <li>Retail & Ecommerce</li>
//               <li>Software & Technology</li>
//               <li>Manufactures</li>
//               <li>Real Estate</li>
//               <li>Healthcare</li>
//               <li>Hospitality</li>
//               <li>Professional Services</li>
//               <li>Consultant & Freelancers</li>
//             </ul>
//           </Col>

//           <Col xs={12} sm={6} md={3} className="mb-4">
//             <h6>Partners</h6>
//             <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
//               <li>Open for Banks</li>
//               <li>Open for Accountant Firms</li>
//               <li>Open for Developers</li>
//               <li>Open for Partner Program</li>
//             </ul>

//             <h6>Company</h6>
//             <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
//               <li>About Us</li>
//               <li>Career - We're hiring!</li>
//               <li>Privacy Policy</li>
//               <li>Terms & Conditions</li>
//               <li>Grievance Policy</li>
//               <li>Trust & Security</li>
//               <li>Corporate Information</li>
//             </ul>

//             <h6 className="mt-4">Resources</h6>
//             <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
//               <li>Blog</li>
//               <li>On-Demand Webinar</li>
//               <li>FAQ</li>
//               <li>Help Centre</li>
//             </ul>

//             <h6 className="mt-4">Let's Talk</h6>
//             <p style={{ fontSize: '0.9rem' }}>Connect with us<br></br> letstalk@open.money</p>
//             <div>
//               <i className="bi bi-facebook me-3" style={{ fontSize: '1.5rem' }}></i>
//               <i className="bi bi-instagram me-3" style={{ fontSize: '1.5rem' }}></i>
//               <i className="bi bi-linkedin me-3" style={{ fontSize: '1.5rem' }}></i>
//               <i className="bi bi-twitter" style={{ fontSize: '1.5rem' }}></i>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// }

// export default Footer;



import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function Footer() {
  return (
    <div style={{ backgroundColor: '#001A35', color: '#FFFFFF', padding: '50px 20px' }}>
      <Container>
        <Row>
          <Col xs={12} sm={6} md={3} className="mb-4">
            <h5>MyPe</h5>
            <p style={{ fontSize: '0.9rem', lineHeight: '1.5' }}>
              MyPe is a connected banking<br /> platform for business payments<br /> that simplifies the process of <br /> sending, receiving,
              and reconciling <br /> payments for businesses.
            </p>
            <p style={{ fontSize: '0.9rem', lineHeight: '1.5' }}>
              With MyPe, businesses can<br /> connect their bank accounts<br /> and accounting software like <br />Tally, Zoho Books
              and more, to<br /> send and receive payments and <br />reconcile their payment<br /> transactions.
            </p>
            <p style={{ fontSize: '0.9rem', lineHeight: '1.5' }}>
              In addition to making payments<br /> and reconciliation easy, MyPe <br /> also offers built-in tools for <br /> GST invoicing,
              payment links <br /> for collecting online payments <br />, and the ability to track account <br />payables and receivables.
              MyPe <br />has partnered with leading banks<br /> like ICICI Bank, State<br /> Bank of India, Axis Bank, <br />Yes Bank, and other
              major <br /> Indian banks for connected banking,<br /> allowing businesses to directly <br /> make payments from the MyPe<br /> dashboard.
              But that's not all - MyPe <br />also offers additional business<br /> tools for managing end-to-end payroll,<br /> expense management,
              and GST &<br /> tax filing directly from the platform.
            </p>
          </Col>

          <Col xs={12} sm={6} md={3} className="mb-4">
            <h6>Get Started</h6>
            <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
              <li>Login</li>
              <li>Sign Up</li>
            </ul>

            <h6 className="mt-4">Products</h6>
            <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
              <li>Pay</li>
              <li>Get Paid</li>
              <li>Spend Management</li>
              <li>Connected Banking</li>
            </ul>
            <h6>Connected Banking</h6>
            <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
              <li>ICICI Connected Banking</li>
              <li>SBI Connected Banking</li>
              <li>Axis Bank Connected Banking</li>
              <li>Yes Bank Connected Banking</li>
            </ul>

            <h6 className="mt-4">Accounting Automation</h6>
            <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
              <li>Accounts Payable Automation</li>
              <li>Accounts Receivable Automation</li>
              <li>e-Invoicing</li>
            </ul>
          </Col>

          <Col xs={12} sm={6} md={3} className="mb-4">
            <h6>Pricing</h6>
            <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
              <li>Get started for free</li>
            </ul>

            <h6 className="mt-4">Business Tools</h6>
            <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
              <li>Open Payroll</li>
              <li>Open Accounting</li>
              <li>GST Returns</li>
            </ul>
            {/* 
            <h6 className="mt-4">Integration & Sync</h6>
            <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
              <li>Tally</li>
              <li>Zoho Books</li>
              <li>Oracle NetSuite</li>
              <li>Microsoft Dynamics</li>
            </ul> */}

            <h6 className="mt-4">Solutions</h6>
            <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
              <li>Small Business</li>
              <li>Startups</li>
              <li>SMEs</li>
              <li>Enterprises</li>
              <li>Retail & Ecommerce</li>
              <li>Software & Technology</li>
              <li>Manufactures</li>
              <li>Real Estate</li>
              <li>Healthcare</li>
              <li>Hospitality</li>
              <li>Professional Services</li>
              <li>Consultant & Freelancers</li>
            </ul>
          </Col>

          <Col xs={12} sm={6} md={3} className="mb-4">
            <h6>Partners</h6>
            <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
              <li>Open for Banks</li>
              <li>Open for Accountant Firms</li>
              <li>Open for Developers</li>
              <li>Open for Partner Program</li>
            </ul>

            <h6>Company</h6>
            <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
              <li>About Us</li>
              <li>Career - We're hiring!</li>
              <li>Privacy Policy</li>
              <li>Terms & Conditions</li>
              <li>Terms & Conditions for Cards</li>
              <li>Terms & Conditions for PPI</li>
              <li>Grievance Policy</li>
              <li>Grievance Policy for PPI</li>
              <li>SBM Bank Policy</li>
              <li>Responsible Disclosure</li>
              <li>Secure Usage Guidelines</li>
              <li>Trust & Security</li>
              <li>Corporate Information</li>
            </ul>

            <h6 className="mt-4">Resources</h6>
            <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
              <li>Blog</li>
              <li>On-Demand Webinar</li>
              <li>FAQ</li>
              <li>Help Centre</li>
            </ul>

            <h6 className="mt-4">Let's Talk</h6>
            <p style={{ fontSize: '0.9rem' }}>Connect with us<br /> palsania@websquaresoftware.com</p>
            <div>
              <i className="bi bi-facebook me-3" style={{ fontSize: '1.5rem' }}></i>
              <i className="bi bi-instagram me-3" style={{ fontSize: '1.5rem' }}></i>
              <i className="bi bi-linkedin me-3" style={{ fontSize: '1.5rem' }}></i>
              <i className="bi bi-twitter" style={{ fontSize: '1.5rem' }}></i>
            </div>
          </Col>
          <hr className="border-0 border-top border-white" />
          <footer className=" text-white py-3">
      <Container>
        <Row className="align-items-center">
          {/* First Column: Left Aligned Text */}
          <Col xs={12} md={6}>
            <p className="mb-0">
              All right Reserved @ Websquare Software Private Limited
            </p>
          </Col>

          {/* Second Column: Right Aligned Text */}
          <Col xs={12} md={6} className="text-md-end text-start">
            <p className="mb-0">
              Address: 311, 3rd Floor, Center Tower, Central Spine,
              Vidhyadhar Nagar, Jaipur-302023 <br />

            </p>
          </Col>
        </Row>
      </Container>
    </footer>

        </Row>
      </Container>
    </div>
  );
}

export default Footer;
