// import React from "react";
// import { Container, Row, Col, Button, Card } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";

// function Mpay() {
//   return (
//     <div style={{ backgroundColor: "#f8f9fa", padding: "40px 0" }}>
//       <Container fluid>
//         <Row className="justify-content-center">
//           <Col lg={8} md={10} sm={12}>
//             <Card
//               style={{
//                 border: "2px solid #B59BCD",
//                 borderRadius: "15px",
//                 transition: "border-color 0.5s ease, box-shadow 0.8s ease",
//                 padding: "30px",
//               }}
//               className="hover-card"
//             >
//               <Card.Body>
//                 <Row className="align-items-center">
//                   {/* Left Section: Text Content */}
//                   <Col lg={6} md={12} className="mb-4 mb-lg-0">
//                     <p
//                       style={{
//                         color: "#752abf",
//                         fontWeight: "bold",
//                         textTransform: "uppercase",
//                         fontSize: '1.5rem',
//                       }}
//                       className="text-center text-lg-start"
//                     >
//                       Pay
//                     </p>
//                     <h1
//                       style={{
//                         fontWeight: "bold",
//                         fontSize: "2rem",
//                         lineHeight: "1.2",
//                         color: "#212529",
//                       }}
//                       className="text-center text-lg-start"
//                     >
//                       Simplify Your Vendor Payments With My Pe
//                     </h1>
//                     <p
//                       style={{
//                         color: "#6c757d",
//                         marginTop: "20px",
//                         fontSize: "1rem",
//                       }}
//                       className="text-center text-lg-start"
//                     >
//                       Handle Accounts Payable like a PRO: Create bills and pay
//                       vendors on the same platform, saving hundreds of hours on
//                       data entry and vendor payment reconciliation.
//                     </p>
//                     <div className="text-center text-lg-start">
//                       <Button
//                         variant="primary"
//                         style={{
//                           backgroundColor: "#752abf",
//                           borderColor: "#752abf",
//                           borderRadius: "50px",
//                           padding: "10px 30px",
//                           fontSize: "1rem",
//                           fontWeight: "bold",
//                           marginTop: "20px",
//                         }}
//                       >
//                         Get Started
//                       </Button>
//                     </div>
//                   </Col>

//                   {/* Right Section: Image */}
//                   <Col lg={6} md={12} className="text-center">
//                     <div
//                       style={{
//                         padding: "0px",
//                         display: "inline-block",
//                       }}
//                     >
//                       <img
//                         src="./assest/mpay.png" // Make sure the path matches your image location
//                         alt="My Pe Logo"
//                         style={{
//                           width: "100%",
//                           maxWidth: "300px",
//                           height: "auto",
//                         }}
//                       />
//                     </div>
//                   </Col>
//                 </Row>
//               </Card.Body>
//             </Card>
//           </Col>
//         </Row>
//       </Container>

//       {/* Custom CSS for hover effect */}
//       <style jsx>{`
//         .hover-card {
//           box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
//         }
//         .hover-card:hover {
//           border-color: #752abf;
//           box-shadow: 10px 10px 20px rgba(117, 42, 191, 0.3);
//         }
//       `}</style>
//     </div>
//   );
// }

// export default Mpay;



import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function Mpay() {
  return (
    <div style={{ backgroundColor: "#f8f9fa", padding: "40px 0" }}>
      <Container fluid>
        <Row className="justify-content-center">
          <Col lg={8} md={10} sm={12}>
            <Card
              style={{
                border: "2px solid #B59BCD",
                borderRadius: "15px",
                transition: "border-color 0.5s ease, box-shadow 0.8s ease",
                padding: "30px",
              }}
              className="hover-card"
            >
              <Card.Body>
                <Row className="align-items-center">
                  {/* Left Section: Text Content */}
                  <Col lg={6} md={12} className="mb-4 mb-lg-0">
                    <p
                      style={{
                        color: "#752abf",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        fontSize: "1.5rem",
                      }}
                      className="text-center text-lg-start"
                    >
                      Pay
                    </p>
                    <h1
                      style={{
                        fontWeight: "bold",
                        fontSize: "2rem",
                        lineHeight: "1.2",
                        color: "#212529",
                      }}
                      className="text-center text-lg-start"
                    >
                      Simplify Your Vendor Payments With MyPe
                    </h1>
                    <p
                      style={{
                        color: "#6c757d",
                        marginTop: "20px",
                        fontSize: "1rem",
                      }}
                      className="text-center text-lg-start"
                    >
                      Handle Accounts Payable like a PRO: Create bills and pay
                      vendors on the same platform, saving hundreds of hours on
                      data entry and vendor payment reconciliation.
                    </p>
                    <div className="text-center text-lg-start">
                      <Button
                        variant="primary"
                        style={{
                          backgroundColor: "#752abf",
                          borderColor: "#752abf",
                          borderRadius: "50px",
                          padding: "10px 30px",
                          fontSize: "1rem",
                          fontWeight: "bold",
                          marginTop: "20px",
                        }}
                      >
                        Get Started
                      </Button>
                    </div>
                  </Col>

                  {/* Right Section: Image */}
                  <Col lg={6} md={12} className="text-center">
                    <div
                      style={{
                        padding: "0px",
                        display: "inline-block",


                      }}
                    >
                      <img
                        src="./assest/mpay.png"
                        alt="My Pe Logo"
                        style={{
                          width: "100%",
                          maxWidth: "300px",
                          height: "400px",
                          // objectFit: "contain", 
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Custom CSS for hover effect */}
      <style jsx>{`
        .hover-card {
          box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
        }
        .hover-card:hover {
          border-color: #752abf;
          box-shadow: 10px 10px 20px rgba(117, 42, 191, 0.3);
        }
      `}</style>
    </div>
  );
}

export default Mpay;
