// // import React, { useState } from 'react';
// // import { Form, Button } from 'react-bootstrap';
// // import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import icons

// // // Reusable component for input fields
// // const FormField = ({
// //     controlId,
// //     label,
// //     type,
// //     placeholder,
// //     value,
// //     onChange,
// //     isInvalid,
// //     errorMessage,
// // }) => {
// //     const [isFocused, setIsFocused] = useState(false);
// //     const [isPasswordVisible, setIsPasswordVisible] = useState(false); // Password visibility state
// //     const hasValue = value && value.trim().length > 0;

// //     return (
// //         <Form.Group controlId={controlId} className="mb-4 position-relative">
// //             <Form.Label
// //                 style={{
// //                     fontSize: '1rem',
// //                     position: 'absolute',
// //                     top: hasValue || isFocused ? '-10px' : '12px',
// //                     left: '10px',
// //                     color: hasValue || isFocused ? '#752abf' : '#6c757d',
// //                     transition: 'all 0.2s ease-in-out',
// //                     background: hasValue || isFocused ? '#fff' : 'transparent',
// //                     padding: hasValue || isFocused ? '0 5px' : '0',
// //                     zIndex: 1,
// //                 }}
// //             >
// //                 {label}
// //             </Form.Label>
// //             <div className="position-relative">
// //                 <Form.Control
// //                     type={type === 'password' && isPasswordVisible ? 'text' : type}
// //                     placeholder={hasValue || isFocused ? '' : placeholder}
// //                     value={value}
// //                     onChange={onChange}
// //                     onFocus={() => setIsFocused(true)}
// //                     onBlur={() => setIsFocused(false)}
// //                     isInvalid={isInvalid}
// //                     style={{
// //                         padding: '15px 40px 15px 10px', // Padding to make space for the icon
// //                         fontSize: '1.1rem',
// //                         border: isInvalid ? '1px solid red' : '1px solid #B59BCD',
// //                         borderRadius: '5px',
// //                     }}
// //                 />
// //                 {type === 'password' && (
// //                     <span
// //                         onClick={() => setIsPasswordVisible(!isPasswordVisible)}
// //                         style={{
// //                             position: 'absolute',
// //                             right: '10px',
// //                             top: '50%',
// //                             transform: 'translateY(-50%)',
// //                             cursor: 'pointer',
// //                             color: '#6c757d',
// //                         }}
// //                     >
// //                         {isPasswordVisible ? <FaEyeSlash size={18} /> : <FaEye size={18} />}
// //                     </span>
// //                 )}
// //             </div>
// //             {isInvalid && (
// //                 <Form.Text className="text-danger" style={{ fontSize: '0.9rem' }}>
// //                     {errorMessage}
// //                 </Form.Text>
// //             )}
// //         </Form.Group>
// //     );
// // };

// // const SignUpPage = () => {
// //     const [formData, setFormData] = useState({
// //         fullName: '',
// //         email: '',
// //         mobile: '',
// //         password: '',
// //     });

// //     const [errors, setErrors] = useState({});

// //     const handleChange = (e) => {
// //         const { id, value } = e.target;
// //         setFormData({ ...formData, [id]: value });
// //     };

// //     const validate = () => {
// //         const newErrors = {};
// //         if (!formData.fullName) newErrors.fullName = 'Full name is required.';
// //         if (!formData.email) newErrors.email = 'Email is required.';
// //         if (!formData.mobile) newErrors.mobile = 'Mobile number is required.';
// //         if (!formData.password) newErrors.password = 'Password is required.';
// //         return newErrors;
// //     };

// //     const handleSubmit = (e) => {
// //         e.preventDefault();
// //         const newErrors = validate();
// //         if (Object.keys(newErrors).length > 0) {
// //             setErrors(newErrors);
// //         } else {
// //             alert('Form submitted successfully!');
// //             setErrors({});
// //         }
// //     };

// //     return (
// //         <div
// //             style={{
// //                 maxWidth: '600px',
// //                 margin: 'auto',
// //                 marginTop: '150px', // Added margin from the top
// //                 padding: '40px',
// //                 border: '1px solid #B59BCD',
// //                 borderRadius: '10px',
// //                 backgroundColor: '#fff',
// //                 boxShadow: '0 4px 10px #B59BCD',
// //             }}
// //         >
// //             <h2 className="text-center fw-bold" style={{ fontSize: '2.8rem' }}>
// //                 Sign Up
// //             </h2>
// //             <p className="text-center" style={{ fontSize: '1.2rem' }}>
// //                 Bring your banking & finance together.
// //             </p>
// //             <Form onSubmit={handleSubmit}>
// //                 <FormField
// //                     controlId="fullName"
// //                     label="Your Full Name*"
// //                     type="text"
// //                     value={formData.fullName}
// //                     onChange={handleChange}
// //                     isInvalid={!!errors.fullName}
// //                     errorMessage={errors.fullName}
// //                 />
// //                 <FormField
// //                     controlId="email"
// //                     label="Your Work Email ID*"
// //                     type="email"
// //                     value={formData.email}
// //                     onChange={handleChange}
// //                     isInvalid={!!errors.email}
// //                     errorMessage={errors.email}
// //                 />
// //                 <FormField
// //                     controlId="mobile"
// //                     label="Your Mobile Number*"
// //                     type="text"
// //                     value={formData.mobile}
// //                     onChange={handleChange}
// //                     isInvalid={!!errors.mobile}
// //                     errorMessage={errors.mobile}
// //                 />
// //                 <FormField
// //                     controlId="password"
// //                     label="Your Password*"
// //                     type="password"
// //                     value={formData.password}
// //                     onChange={handleChange}
// //                     isInvalid={!!errors.password}
// //                     errorMessage={errors.password}
// //                 />

// //                 <Form.Group controlId="terms" className="mb-4">
// //                     <Form.Check
// //                         type="checkbox"
// //                         label={
// //                             <>
// //                                 I agree to Open's{' '}
// //                                 <a href="#terms" style={{ color: '#4e2c75', fontSize: '1.1rem' }}>
// //                                     Terms of Service
// //                                 </a>{' '}
// //                                 &{' '}
// //                                 <a href="#privacy" style={{ color: '#4e2c75', fontSize: '1.1rem' }}>
// //                                     Privacy Policy
// //                                 </a>
// //                             </>
// //                         }
// //                         required
// //                     />
// //                 </Form.Group>

// //                 <Button
// //                     style={{
// //                         background: '#752abf',
// //                         borderRadius: '1.5rem',
// //                         width: 'fit-content',
// //                         border: 'none',
// //                     }}
// //                     type="submit"
// //                     className="w-100 mb-4"
// //                 >
// //                     Sign Up
// //                 </Button>
// //             </Form>
// //         </div>
// //     );
// // };

// // export default SignUpPage;


// import React, { useState } from 'react';
// import { Form, Button } from 'react-bootstrap';
// import { FaEye, FaEyeSlash } from 'react-icons/fa';
// import { Link, useNavigate } from 'react-router-dom'; // Import Link for navigation

// const FormField = ({
//     controlId,
//     label,
//     type,
//     placeholder,
//     value,
//     onChange,
//     isInvalid,
//     errorMessage,
// }) => {
//     const [isFocused, setIsFocused] = useState(false);
//     const [isPasswordVisible, setIsPasswordVisible] = useState(false);
//     const hasValue = value && value.trim().length > 0;

//     return (
//         <Form.Group controlId={controlId} className="mb-4 position-relative">
//             <Form.Label
//                 style={{
//                     fontSize: '1rem',
//                     position: 'absolute',
//                     top: hasValue || isFocused ? '-10px' : '12px',
//                     left: '10px',
//                     color: hasValue || isFocused ? '#752abf' : '#6c757d',
//                     transition: 'all 0.2s ease-in-out',
//                     background: hasValue || isFocused ? '#fff' : 'transparent',
//                     padding: hasValue || isFocused ? '0 5px' : '0',
//                     zIndex: 1,
//                 }}
//             >
//                 {label}
//             </Form.Label>
//             <div className="position-relative">
//                 <Form.Control
//                     type={type === 'password' && isPasswordVisible ? 'text' : type}
//                     placeholder={hasValue || isFocused ? '' : placeholder}
//                     value={value}
//                     onChange={onChange}
//                     onFocus={() => setIsFocused(true)}
//                     onBlur={() => setIsFocused(false)}
//                     isInvalid={isInvalid}
//                     style={{
//                         padding: '15px 40px 15px 10px',
//                         fontSize: '1.1rem',
//                         border: isInvalid ? '1px solid red' : '1px solid #B59BCD',
//                         borderRadius: '5px',
//                     }}
//                 />
//                 {type === 'password' && (
//                     <span
//                         onClick={() => setIsPasswordVisible(!isPasswordVisible)}
//                         style={{
//                             position: 'absolute',
//                             right: '10px',
//                             top: '50%',
//                             transform: 'translateY(-50%)',
//                             cursor: 'pointer',
//                             color: '#6c757d',
//                         }}
//                     >
//                         {isPasswordVisible ? <FaEyeSlash size={18} /> : <FaEye size={18} />}
//                     </span>
//                 )}
//             </div>
//             {isInvalid && (
//                 <Form.Text className="text-danger" style={{ fontSize: '0.9rem' }}>
//                     {errorMessage}
//                 </Form.Text>
//             )}
//         </Form.Group>
//     );
// };

// const SignUpPage = () => {
//     const [formData, setFormData] = useState({
//         fullName: '',
//         email: '',
//         mobile: '',
//         password: '',
//     });

//     const [errors, setErrors] = useState({});

//     const handleChange = (e) => {
//         const { id, value } = e.target;
//         setFormData({ ...formData, [id]: value });
//     };

//     const validate = () => {
//         const newErrors = {};
//         if (!formData.fullName) newErrors.fullName = 'Full name is required.';
//         if (!formData.email) newErrors.email = 'Email is required.';
//         if (!formData.mobile) newErrors.mobile = 'Mobile number is required.';
//         if (!formData.password) newErrors.password = 'Password is required.';
//         return newErrors;
//     };
//    const navigate = useNavigate()
//     const handleSubmit = (e) => {
//         e.preventDefault();
//         const newErrors = validate();
//         if (Object.keys(newErrors).length > 0) {
//             setErrors(newErrors);
//         } else {
//             alert('Form submitted successfully!');
//             navigate("/otp-verification")
//             setErrors({});
//         }
//     };

//     return (
//         <div
//             style={{
//                 maxWidth: '600px',
//                 margin: 'auto',
//                 marginTop: '110px',
//                 padding: '40px',
//                 border: '1px solid #B59BCD',
//                 borderRadius: '10px',
//                 backgroundColor: '#fff',
//                 boxShadow: '0 4px 10px #B59BCD',
//             }}
//         >
//             <h2 className="text-center fw-bold" style={{ fontSize: '2.8rem' }}>
//                 Sign Up
//             </h2>
//             <p className="text-center" style={{ fontSize: '1.2rem' }}>
//                 Bring your banking & finance together.
//             </p>
//             <Form onSubmit={handleSubmit}>
//                 <FormField
//                     controlId="fullName"
//                     label="Your Full Name*"
//                     type="text"
//                     value={formData.fullName}
//                     onChange={handleChange}
//                     isInvalid={!!errors.fullName}
//                     errorMessage={errors.fullName}
//                 />
//                 <FormField
//                     controlId="email"
//                     label="Your Work Email ID*"
//                     type="email"
//                     value={formData.email}
//                     onChange={handleChange}
//                     isInvalid={!!errors.email}
//                     errorMessage={errors.email}
//                 />
//                 <FormField
//                     controlId="mobile"
//                     label="Your Mobile Number*"
//                     type="text"
//                     value={formData.mobile}
//                     onChange={handleChange}
//                     isInvalid={!!errors.mobile}
//                     errorMessage={errors.mobile}
//                 />
//                 <FormField
//                     controlId="password"
//                     label="Your Password*"
//                     type="password"
//                     value={formData.password}
//                     onChange={handleChange}
//                     isInvalid={!!errors.password}
//                     errorMessage={errors.password}
//                 />

//                 <Form.Group controlId="terms" className="mb-4 mt-3">
//                     <Form.Check
//                         type="checkbox" 
//                         label={
//                             <>
//                                 I agree to My Pe's{' '}
//                                 <a href="/terms" style={{ color: '#4e2c75', fontSize: '1.1rem' }}>
//                                     Terms of Service
//                                 </a>{' '}
//                                 &{' '}
//                                 <Link to="/privacy" style={{ color: '#4e2c75', fontSize: '1.1rem' }}>
//                                     Privacy Policy
//                                 </Link>
//                             </>
//                         }
//                         required
//                     />
//                 </Form.Group>

//                 <Button
//                     style={{
//                         background: '#752abf',
//                         borderRadius: '1.5rem',
//                         width: 'fit-content',
//                         border: 'none',
//                     }}
//                     type="submit"
//                     className="w-100 mb-4"
//                 >
//                     Sign Up
//                 </Button>
//             </Form>
//         </div>
//     );
// };

// export default SignUpPage;


// import React, { useState } from 'react';
// import { Form, Button } from 'react-bootstrap';
// import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import icons

// // Reusable component for input fields
// const FormField = ({
//     controlId,
//     label,
//     type,
//     placeholder,
//     value,
//     onChange,
//     isInvalid,
//     errorMessage,
// }) => {
//     const [isFocused, setIsFocused] = useState(false);
//     const [isPasswordVisible, setIsPasswordVisible] = useState(false); // Password visibility state
//     const hasValue = value && value.trim().length > 0;

//     return (
//         <Form.Group controlId={controlId} className="mb-4 position-relative">
//             <Form.Label
//                 style={{
//                     fontSize: '1rem',
//                     position: 'absolute',
//                     top: hasValue || isFocused ? '-10px' : '12px',
//                     left: '10px',
//                     color: hasValue || isFocused ? '#752abf' : '#6c757d',
//                     transition: 'all 0.2s ease-in-out',
//                     background: hasValue || isFocused ? '#fff' : 'transparent',
//                     padding: hasValue || isFocused ? '0 5px' : '0',
//                     zIndex: 1,
//                 }}
//             >
//                 {label}
//             </Form.Label>
//             <div className="position-relative">
//                 <Form.Control
//                     type={type === 'password' && isPasswordVisible ? 'text' : type}
//                     placeholder={hasValue || isFocused ? '' : placeholder}
//                     value={value}
//                     onChange={onChange}
//                     onFocus={() => setIsFocused(true)}
//                     onBlur={() => setIsFocused(false)}
//                     isInvalid={isInvalid}
//                     style={{
//                         padding: '15px 40px 15px 10px', // Padding to make space for the icon
//                         fontSize: '1.1rem',
//                         border: isInvalid ? '1px solid red' : '1px solid #B59BCD',
//                         borderRadius: '5px',
//                     }}
//                 />
//                 {type === 'password' && (
//                     <span
//                         onClick={() => setIsPasswordVisible(!isPasswordVisible)}
//                         style={{
//                             position: 'absolute',
//                             right: '10px',
//                             top: '50%',
//                             transform: 'translateY(-50%)',
//                             cursor: 'pointer',
//                             color: '#6c757d',
//                         }}
//                     >
//                         {isPasswordVisible ? <FaEyeSlash size={18} /> : <FaEye size={18} />}
//                     </span>
//                 )}
//             </div>
//             {isInvalid && (
//                 <Form.Text className="text-danger" style={{ fontSize: '0.9rem' }}>
//                     {errorMessage}
//                 </Form.Text>
//             )}
//         </Form.Group>
//     );
// };

// const SignUpPage = () => {
//     const [formData, setFormData] = useState({
//         fullName: '',
//         email: '',
//         mobile: '',
//         password: '',
//     });

//     const [errors, setErrors] = useState({});

//     const handleChange = (e) => {
//         const { id, value } = e.target;
//         setFormData({ ...formData, [id]: value });
//     };

//     const validate = () => {
//         const newErrors = {};
//         if (!formData.fullName) newErrors.fullName = 'Full name is required.';
//         if (!formData.email) newErrors.email = 'Email is required.';
//         if (!formData.mobile) newErrors.mobile = 'Mobile number is required.';
//         if (!formData.password) newErrors.password = 'Password is required.';
//         return newErrors;
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         const newErrors = validate();
//         if (Object.keys(newErrors).length > 0) {
//             setErrors(newErrors);
//         } else {
//             alert('Form submitted successfully!');
//             setErrors({});
//         }
//     };

//     return (
//         <div
//             style={{
//                 maxWidth: '600px',
//                 margin: 'auto',
//                 marginTop: '150px', // Added margin from the top
//                 padding: '40px',
//                 border: '1px solid #B59BCD',
//                 borderRadius: '10px',
//                 backgroundColor: '#fff',
//                 boxShadow: '0 4px 10px #B59BCD',
//             }}
//         >
//             <h2 className="text-center fw-bold" style={{ fontSize: '2.8rem' }}>
//                 Sign Up
//             </h2>
//             <p className="text-center" style={{ fontSize: '1.2rem' }}>
//                 Bring your banking & finance together.
//             </p>
//             <Form onSubmit={handleSubmit}>
//                 <FormField
//                     controlId="fullName"
//                     label="Your Full Name*"
//                     type="text"
//                     value={formData.fullName}
//                     onChange={handleChange}
//                     isInvalid={!!errors.fullName}
//                     errorMessage={errors.fullName}
//                 />
//                 <FormField
//                     controlId="email"
//                     label="Your Work Email ID*"
//                     type="email"
//                     value={formData.email}
//                     onChange={handleChange}
//                     isInvalid={!!errors.email}
//                     errorMessage={errors.email}
//                 />
//                 <FormField
//                     controlId="mobile"
//                     label="Your Mobile Number*"
//                     type="text"
//                     value={formData.mobile}
//                     onChange={handleChange}
//                     isInvalid={!!errors.mobile}
//                     errorMessage={errors.mobile}
//                 />
//                 <FormField
//                     controlId="password"
//                     label="Your Password*"
//                     type="password"
//                     value={formData.password}
//                     onChange={handleChange}
//                     isInvalid={!!errors.password}
//                     errorMessage={errors.password}
//                 />

//                 <Form.Group controlId="terms" className="mb-4">
//                     <Form.Check
//                         type="checkbox"
//                         label={
//                             <>
//                                 I agree to Open's{' '}
//                                 <a href="#terms" style={{ color: '#4e2c75', fontSize: '1.1rem' }}>
//                                     Terms of Service
//                                 </a>{' '}
//                                 &{' '}
//                                 <a href="#privacy" style={{ color: '#4e2c75', fontSize: '1.1rem' }}>
//                                     Privacy Policy
//                                 </a>
//                             </>
//                         }
//                         required
//                     />
//                 </Form.Group>

//                 <Button
//                     style={{
//                         background: '#752abf',
//                         borderRadius: '1.5rem',
//                         width: 'fit-content',
//                         border: 'none',
//                     }}
//                     type="submit"
//                     className="w-100 mb-4"
//                 >
//                     Sign Up
//                 </Button>
//             </Form>
//         </div>
//     );
// };

// export default SignUpPage;



import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom'; 

const FormField = ({
    controlId,
    label,
    type,
    placeholder,
    value,
    onChange,
    isInvalid,
    errorMessage,
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const hasValue = value && value.trim().length > 0;

    return (
        <Form.Group controlId={controlId} className="mb-4 position-relative">
            <Form.Label
                style={{
                    fontSize: '1rem',
                    position: 'absolute',
                    top: hasValue || isFocused ? '-10px' : '12px',
                    left: '10px',
                    color: hasValue || isFocused ? '#752abf' : '#6c757d',
                    transition: 'all 0.2s ease-in-out',
                    background: hasValue || isFocused ? '#fff' : 'transparent',
                    padding: hasValue || isFocused ? '0 5px' : '0',
                    zIndex: 1,
                }}
            >
                {label}
            </Form.Label>
            <div className="position-relative">
                <Form.Control
                    type={type === 'password' && isPasswordVisible ? 'text' : type}
                    placeholder={hasValue || isFocused ? '' : placeholder}
                    value={value}
                    onChange={onChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    isInvalid={isInvalid}
                    style={{
                        padding: '15px 40px 15px 10px',
                        fontSize: '1.1rem',
                        border: isInvalid ? '1px solid red' : '1px solid #B59BCD',
                        borderRadius: '5px',
                    }}
                />
                {type === 'password' && (
                    <span
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                            color: '#6c757d',
                        }}
                    >
                        {isPasswordVisible ? <FaEyeSlash size={18} /> : <FaEye size={18} />}
                    </span>
                )}
            </div>
            {isInvalid && (
                <Form.Text className="text-danger" style={{ fontSize: '0.9rem' }}>
                    {errorMessage}
                </Form.Text>
            )}
        </Form.Group>
    );
};

const SignUpPage = ({setshow}) => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        mobile: '',
        password: '',
    }); 
 


    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.fullName) newErrors.fullName = 'Full name is required.';
        if (!formData.email) newErrors.email = 'Email is required.';
        if (!formData.mobile) newErrors.mobile = 'Mobile number is required.';
        if (!formData.password) newErrors.password = 'Password is required.';
        return newErrors;
    };
    const navigate = useNavigate()
    const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = validate();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            alert('Form submitted successfully!');
            navigate("/otp-verification")
            setErrors({});
        }
    };
    const handleLoginClick = () => {
        navigate('/login'); // Replace '/loginPage' with your actual route
    };
    return (
        <div
            style={{
                maxWidth: '600px',
                margin: 'auto',
                marginTop: '90px',
                padding: '40px',
                border: '1px solid #B59BCD',
                borderRadius: '10px',
                backgroundColor: '#fff',
                boxShadow: '0 4px 10px #B59BCD',
            }}
        >
            <h2 className="text-center fw-bold" style={{ fontSize: '2.8rem' }}>
                Sign Up
            </h2>
            <p className="text-center" style={{ fontSize: '1.2rem' }}>
                Bring your banking & finance together.
            </p>
            <Form onSubmit={handleSubmit}>
                <FormField
                    controlId="fullName"
                    label="Your Full Name*"
                    type="text"
                    value={formData.fullName}
                    onChange={handleChange}
                    isInvalid={!!errors.fullName}
                    errorMessage={errors.fullName}
                />
                <FormField
                    controlId="email"
                    label="Your Work Email ID*"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    isInvalid={!!errors.email}
                    errorMessage={errors.email}
                />
                <FormField
                    controlId="mobile"
                    label="Your Mobile Number*"
                    type="text"
                    value={formData.mobile}
                    onChange={handleChange}
                    isInvalid={!!errors.mobile}
                    errorMessage={errors.mobile}
                />
                <FormField
                    controlId="password"
                    label="Your Password*"
                    type="password"
                    value={formData.password}
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                    errorMessage={errors.password}
                />

                <Form.Group controlId="terms" className="mb-4 mt-3">
                    <Form.Check
                        type="checkbox"
                        label={
                            <>
                                I agree to Eazy Pe's{' '}
                                <a href="/terms" style={{ color: '#4e2c75', fontSize: '1.1rem' }}>
                                    Terms of Service
                                </a>{' '}
                                &{' '}
                                <Link to="/privacy" style={{ color: '#4e2c75', fontSize: '1.1rem' }}>
                                    Privacy Policy
                                </Link>
                            </>
                        }
                        required
                    />
                </Form.Group>

                <Button
                    style={{
                        background: '#752abf',
                        borderRadius: '1.5rem',
                        width: 'fit-content',
                        border: 'none',
                    }}
                    type="submit"
                    className="w-100 mb-4"
                >
                    Sign Up
                </Button>
            
               <div className="text-center">
            <p style={{ fontSize: '1.1rem' }}>
                Don't have an account?{' '}
                <button
                    style={{
                        background: 'none',
                        border: 'none',
                        color: '#752abf',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        fontSize: '1.1rem',
                        padding: 0
                    }}
                    onClick={handleLoginClick}
                >
                    Login
                </button>
            </p>
        </div>

            </Form>
        </div>
    );
};

export default SignUpPage;
