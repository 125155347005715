

// import React, { useState } from 'react';
// import { Link } from "react-router-dom";
// import { Navbar, Nav, Button, Container } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

// function CustomNavbar() {
//   const [showSolutions, setShowSolutions] = useState(false);
//   const [showProducts, setShowProducts] = useState(false);

//   return (
//     <>
//       <Navbar expand="lg" bg="white" variant="light" className="shadow-sm" style={{ marginBottom: '0px', position: 'relative' }} sticky="top">
//         <Container >
//           <Navbar.Brand href="#">
//             <img src="./assest/alogo.jpeg" alt="Logo" style={{ width: '200px', height: 'auto' }} />
//           </Navbar.Brand>

//           <Navbar.Toggle aria-controls="basic-navbar-nav" />
//           <Navbar.Collapse id="basic-navbar-nav" className="justify-content-between">
//             <Nav className="me-auto" style={{ fontSize: '1.1rem' }}>
//               <div
//                 onMouseEnter={() => setShowProducts(true)}
//                 onMouseLeave={() => setShowProducts(false)}
//                 style={{ position: 'relative' ,alignContent:"center" }}
//               >

//                 <span className="text-black mx-2 ">Products</span>
//               </div>

//               {/* Solutions Dropdown */}
//               <div
//                 onMouseEnter={() => setShowSolutions(true)}
//                 onMouseLeave={() => setShowSolutions(false)}
//                 style={{ position: 'relative', alignContent:"center"}}
//               >
//                 <span className="text-black mx-2">Solutions</span>
//               </div>

//               <Nav.Link href="#partners" className="mx-2 text-black">Partners</Nav.Link>
//               <Nav.Link href="#company" className="mx-2 text-black">Company</Nav.Link>
//               <Nav.Link href="#resources" className="mx-2 text-black">Resources</Nav.Link>
//               <Nav.Link href="#pricing" className="mx-2 text-black">Pricing</Nav.Link>
//             </Nav>

//             <Nav>
//               <Nav.Link href="#contact" className="mx-2" style={{ color: '#752abf', fontSize: '1.1rem' }}>Contact Sales</Nav.Link>

//          <Link to="/">
//          <Button
//                 variant="outline-white"
//                 className="me-3 px-3 w-auto d-inline-block w-sm-auto"
//                 style={{
//                   borderRadius: '50px',
//                   color: '#4e2c75',
//                   fontSize: '1rem',
//                   borderColor: "#4e2c75",
//                 }}
//               >
//                 Login
//               </Button>

//               </Link>


//               <Link to="/singup">
//                 <Button
//                   className="px-4"
//                   style={{ borderRadius: '50px', color: '#fcf9f9', background: '#752abf', fontSize: '1.1rem', border: 'none' }}
//                 >
//                   Registration
//                 </Button>

//               </Link>
//             </Nav>
//           </Navbar.Collapse>
//         </Container>
//       </Navbar>

//       {showProducts &&
//         <div style={{
//           position: 'absolute',
//           left: 0,
//           width: '100%',
//           backgroundColor: 'white',
//           padding: '15px',
//           boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//           zIndex: 9999, 
//           display: 'flex',
//           flexDirection: 'row',
//           justifyContent: 'center'
//         }}>
//          <container style={{display:'flex'}}>
//          <div style={{ flex:'1' }}>
//             <Nav.Link href="#pay">Pay</Nav.Link>
//             <Nav.Link href="#electricity">Electricity</Nav.Link>
//             <Nav.Link href="#gas-piped">Gas-Piped</Nav.Link>
//             <Nav.Link href="#lpg-gasline">LPG Gasline</Nav.Link>
//             <Nav.Link href="#water">Water</Nav.Link>
//             <Nav.Link href="#municipal-taxes-services">Municipal Taxes & Services</Nav.Link>
//           </div>
//           <div style={{ flex:'1' }}>
//             <Nav.Link href="#mobile-postpaid-landline-postpaid">Mobile Postpaid / Landline Postpaid</Nav.Link>
//             <Nav.Link href="#mobile-prepaid-recharges">Mobile Prepaid Recharges</Nav.Link>
//             <Nav.Link href="#broadband-datacard">Broadband / Datacard</Nav.Link>
//             <Nav.Link href="#cable">Cable</Nav.Link>
//             <Nav.Link href="#dth">DTH</Nav.Link>
//           </div>
//          </container>
//         </div>
//       }
//       {showSolutions && <div style={{
//           position: 'absolute', 
//           left: 0,
//           width: '100%',
//           backgroundColor: 'white',
//           padding: '15px',
//           boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//         zIndex: 9999,
//         display: 'flex',
//           flexDirection: 'row',
//           justifyContent: 'center'
//         }}>
//                   <container style={{display:"flex"}}>
//                   <div style={{ flex: 1}}>
//                     <p>OVERVIEW</p>
//                     <Nav.Link  href="#small-businesses">Small Businesses</Nav.Link> 
//                     <Nav.Link  href="#startups">Startups</Nav.Link >
//                     <Nav.Link  href="#smes">SMEs</Nav.Link >
//                     <Nav.Link  href="#enterprises">Enterprises</Nav.Link >
//                   </div>
//                   <div style={{ flex: 1 }}>

//                     <p>BY INDUSTRY</p>
//                     <Nav.Link  href="#retail-ecommerce">Retail & Ecommerce</Nav.Link >
//                     <Nav.Link  href="#manufacturers">Manufacturers</Nav.Link >
//                     <Nav.Link  href="#real-estates">Real Estates</Nav.Link >
//                     <Nav.Link  href="#software-technology">Software & Technology</Nav.Link >
//                   </div>
//                   <div style={{ flex: 1 }}>
//                     <Nav.Link  href="#healthcare">Healthcare</Nav.Link >
//                     <Nav.Link  href="#hospitality">Hospitality</Nav.Link >
//                     <Nav.Link  href="#professional-services">Professional Services</Nav.Link >
//                     <Nav.Link  href="#consultants-freelancers">Consultants & Freelancers</Nav.Link >
//                   </div>
//                   </container>
//                 </div>}

//     </>
//   );
// }

// export default CustomNavbar;



import React, { useState, useRef } from 'react';
import { Link } from "react-router-dom";
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function CustomNavbar() {
  const [hoveredMenu, setHoveredMenu] = useState(null); // Tracks which menu is open
  const closeTimeoutRef = useRef(null); // Ref to store the timeout for closing dropdown

  const handleMouseEnter = (menu) => {
    clearTimeout(closeTimeoutRef.current); // Clear any existing timeout
    setHoveredMenu(menu); // Open the hovered menu
  };

  const handleMouseLeave = () => {
    closeTimeoutRef.current = setTimeout(() => {
      setHoveredMenu(null); 
    }, 200); 
  };

  
  return (
    <>
      {/* Navbar */}
      <Navbar expand="lg" bg="white" variant="light" className="shadow-sm" sticky="top">
        <Container>
          <Navbar.Brand href="#">
            <img src="./assest/alogo.jpeg" alt="Logo" style={{ width: '200px', height: 'auto' }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-between">
            <Nav className="me-auto" style={{ fontSize: '1.1rem' }}>
              <div
                 className='mt-2'
                onMouseEnter={() => handleMouseEnter("products")}
                onClick={() => handleMouseEnter("products")}
                onMouseLeave={handleMouseLeave}
                style={{ position: 'relative', cursor: 'pointer' }}
              >
                <span className="text-black mx-2">Products</span>

              </div>

              <div
              className='mt-2'
                onMouseEnter={() => handleMouseEnter("solutions")}
                onClick={() => handleMouseEnter("solutions")}
                onMouseLeave={handleMouseLeave}
                style={{ position: 'relative', cursor: 'pointer' }}
              >
                <span className="text-black mx-3 ">Solutions</span>

              </div>

              <Nav.Link href="#partners" className="mx-2 text-black">Partners</Nav.Link>
              <Nav.Link href="#company" className="mx-2 text-black">Company</Nav.Link>
              <Nav.Link href="#resources" className="mx-2 text-black">Resources</Nav.Link>
              <Nav.Link href="#pricing" className="mx-2 text-black">Pricing</Nav.Link>
            </Nav>

            <Nav>
              <Nav.Link href="#contact" className="mx-2" style={{ color: '#752abf', fontSize: '1.1rem' }}>Contact Sales</Nav.Link>
              <Link to="/login">
                <Button
                  variant="outline-white"
                  className="me-3 px-3 w-auto d-inline-block w-sm-auto"
                  style={{
                    borderRadius: '50px',
                    color: '#4e2c75',
                    fontSize: '1rem',
                    borderColor: "#4e2c75",
                  }}
                >
                  Login
                </Button>
              </Link>
              <Link to="/singup">
                <Button
                  className="px-4"
                  style={{ borderRadius: '50px', color: '#fcf9f9', background: '#752abf', fontSize: '1.1rem', border: 'none' }}
                >
                  Registration
                </Button>
              </Link>
            </Nav>

          </Navbar.Collapse>
        </Container>
      </Navbar>
      {hoveredMenu === "products" && (
        <div
          style={dropdownStyle}
          onMouseEnter={() => handleMouseEnter("products")}
          onMouseLeave={handleMouseLeave}
        >
          <Container style={dropdownContentStyle}>
            <div style={dropdownColumnStyle}>
              <Nav.Link href="#pay">Pay</Nav.Link>
              <Nav.Link href="#electricity">Electricity</Nav.Link>
              <Nav.Link href="#gas-piped">Gas-Piped</Nav.Link>
              <Nav.Link href="#lpg-gasline">LPG Gasline</Nav.Link>
              <Nav.Link href="#water">Water</Nav.Link>
              <Nav.Link href="#municipal-taxes-services">Municipal Taxes & Services</Nav.Link>
            </div>
            <div style={dropdownColumnStyle}>
              <Nav.Link href="#mobile-postpaid-landline-postpaid">Mobile Postpaid / Landline Postpaid</Nav.Link>
              <Nav.Link href="#mobile-prepaid-recharges">Mobile Prepaid Recharges</Nav.Link>
              <Nav.Link href="#broadband-datacard">Broadband / Datacard</Nav.Link>
              <Nav.Link href="#cable">Cable</Nav.Link>
              <Nav.Link href="#dth">DTH</Nav.Link>
            </div>
          </Container>
        </div>
      )}
      {hoveredMenu === "solutions" && (
        <div
          style={dropdownStyle}
          onMouseEnter={() => handleMouseEnter("solutions")}
          onMouseLeave={handleMouseLeave}
        >
          <Container style={dropdownContentStyle}>
            <div style={dropdownColumnStyle}>
              <p>OVERVIEW</p>
              <Nav.Link href="#small-businesses">Small Businesses</Nav.Link>
              <Nav.Link href="#startups">Startups</Nav.Link>
              <Nav.Link href="#smes">SMEs</Nav.Link>
              <Nav.Link href="#enterprises">Enterprises</Nav.Link>
            </div>
            <div style={dropdownColumnStyle}>
              <p>BY INDUSTRY</p>
              <Nav.Link href="#retail-ecommerce">Retail & Ecommerce</Nav.Link>
              <Nav.Link href="#manufacturers">Manufacturers</Nav.Link>
              <Nav.Link href="#real-estates">Real Estates</Nav.Link>
              <Nav.Link href="#software-technology">Software & Technology</Nav.Link>
            </div>
            <div style={dropdownColumnStyle}>
              <Nav.Link href="#healthcare">Healthcare</Nav.Link>
              <Nav.Link href="#hospitality">Hospitality</Nav.Link>
              <Nav.Link href="#professional-services">Professional Services</Nav.Link>
              <Nav.Link href="#consultants-freelancers">Consultants & Freelancers</Nav.Link>
            </div>
          </Container>
        </div>
      )}
    </>
  );
}

const dropdownStyle = {
  position: 'absolute',
  left: 0,
  width: '100%',
  backgroundColor: 'white',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  zIndex: 9999,
  padding: '20px 0',
  display:'flex' ,
   justifyContent: 'center',

};

const dropdownContentStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  width: "auto"
};

const dropdownColumnStyle = {
  flex: 1,
  padding: '0 20px',
};

export default CustomNavbar;


