import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Statistics = () => {
  return (
    <Container className="my-5 text-center">
      <h3 className="fw-bold mb-5 mt-5 fs-1 fs-md-2 fs-lg-3">
        Join India's Largest Business Payment Platform <br />
        Trusted By Millions Of Businesses And Their Finance Teams
      </h3>


      <Row className="justify-content-center">
        <Col xs={12} md={6} lg={4} className="mb-5 d-flex justify-content-center">
          <Card
            className="p-4 border-0 d-flex flex-column justify-content-center align-items-center"
            style={{
              backgroundColor: '#f5f0ff',
              borderRadius: '12px',
              minHeight: '250px',
            }}
          >
            <h3 className="text-purple" style={{ color: '#752abf', fontSize: '4rem', fontWeight: 'bold', margin: 0 }}>
              150+
            </h3>
            <p className="text-muted" style={{ fontSize: '1.25rem', marginTop: '15px' }}>
              businesses use MyPe to manage their business payments
            </p>
          </Card>
        </Col>

        <Col xs={12} md={6} lg={4} className="mb-5 d-flex justify-content-center">
          <Card
            className="p-4 border-0 d-flex flex-column justify-content-center align-items-center"
            style={{
              backgroundColor: '#f5f0ff',
              borderRadius: '12px',
              minHeight: '250px',
            }}
          >
            <h3 className="text-purple" style={{ color: '#752abf', fontSize: '4rem', fontWeight: 'bold', margin: 0 }}>
              100+
            </h3>
            <p className="text-muted" style={{ fontSize: '1.25rem', marginTop: '15px' }}>
              Tax practitioners use MyPe to manage their client payments
            </p>
          </Card>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col xs={12} md={6} lg={4} className="mb-5 d-flex justify-content-center">
          <Card
            className="p-4 border-0 d-flex flex-column justify-content-center align-items-center"
            style={{
              backgroundColor: '#f5f0ff',
              borderRadius: '12px',
              minHeight: '250px',
            }}
          >
            <h3 className="text-purple" style={{ color: '#752abf', fontSize: '4rem', fontWeight: 'bold', margin: 0 }}>
              200+
            </h3>
            <p className="text-muted" style={{ fontSize: '1.25rem', marginTop: '15px' }}>
              Tax practitioners use MyPe to manage their client payments
            </p>
          </Card>
        </Col>

        <Col xs={12} md={6} lg={4} className="mb-5 d-flex justify-content-center">
          <Card
            className="p-4 border-0 d-flex flex-column justify-content-center align-items-center"
            style={{
              backgroundColor: '#f5f0ff',
              borderRadius: '12px',
              minHeight: '250px',
            }}
          >
            <h3 className="text-purple" style={{ color: '#752abf', fontSize: '4rem', fontWeight: 'bold', margin: 0 }}>
              180+
            </h3>
            <p className="text-muted" style={{ fontSize: '1.25rem', marginTop: '15px' }}>
              Tax practitioners use MyPe to manage their client payments
            </p>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Statistics;
