import React from "react";

const TermsOfService = () => {
  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "800px",
        margin: "auto",
        marginTop: "100px",
      }}
    >
      <h1
        className="fw-bold"
        style={{ fontSize: "2.5rem", textAlign: "center" }}
      >
        Terms of Service
      </h1>
      <p style={{ fontSize: "1.2rem", marginTop: "20px" }}>
        The objectives of these terms of use “Terms” are to inform you of the
        terms which apply to the access/use of the MyPe Platform defined below.
        The My Pe Platform enables merchants and businesses to leverage
        technology to manage their invoicing, payments and collections, banking,
        accounting, payroll, tax and compliance from a unified dashboard. These
        Terms are an electronic record in terms of the Information Technology
        Act, 2000 and rules thereunder as applicable and as amended from time to
        time. This electronic record is generated by a computer system and does
        not require any physical or digital signatures. These Terms governing
        the MyPe Platform and Services are published in accordance with the
        provisions of Rule 3(1)(a) of the Information Technology (Intermediary
        Guidelines and Digital Media Ethics Code) Rules, 2021 framed under the
        Information Technology Act, 2000 (as amended from time to time), and
        constitute a legally enforceable and binding contract between MyPe and
        the User as defined below. These terms of use consist of two parts:
      </p>
      <ul style={{ fontSize: "1.1rem" }}>
        <li>
          Part A contains the general terms and conditions which govern the
          access and use of the web link: https://MyPe.money and mobile
          application by the name of ‘MyPe’ (“Website” or “MyPe Platform”),
          which includes any and all the related mini-links and the Services
          provided thereunder offered by MyPe Financial Technologies Private
          Limited (also referred to as “MyPe”, “Company”, “We”, “Us” and “Our”
          as the context requires, and includes its affiliates [including but
          not limited to: MyPedifi Technologies Private Limited and MyPe
          Financial Technologies, Inc.], assigns and Service Providers).
        </li>
        <li>
          Part B contains specific terms and conditions that govern the use of
          each of the Services (defined below) to the extent such Services are
          availed by the User (defined below). The general terms and conditions
          provided under Part A (“General Terms”) shall be read and understood
          in conjunction with the Service-specific terms and conditions provided
          under Part B (“Service Terms”).
        </li>
      </ul>
      <p style={{ fontSize: "1.1rem" }}>
        <strong>General Disclaimer:</strong> MyPe is a technology service
        provider and a payment aggregator regulated and licensed by the RBI. It
        is not a bank and does not hold or claim to hold a banking license. The
        business current accounts, VISA corporate cards, business loans and
        other similar banking services/financial services offered on the MyPe
        platform are provided by the Banks (defined below) and all funds in a
        Business Current Account (defined below) are normally insured as per
        limits prescribed by the Deposit Insurance and Credit Guarantee
        Corporation (DICGC). If in doubt, please make an enquiry with the
        concerned Bank. Banking services/financial services offered on the MyPe
        Platform are subject to security standards and legal requirements
        prescribed by the Bank, in accordance with extant RBI regulations.
      </p>
      <h2 style={{ marginTop: "20px" }}>PART A (General Terms & Conditions)</h2>
      <p style={{ fontSize: "1.1rem" }}>
        These General Terms govern the User’s use and/or access to the Website.
        These Terms form an integral part of, and will be read in conjunction
        with:
      </p>
      <ul style={{ fontSize: "1.1rem" }}>
        <li>
          The privacy policy governing the use of the Website or provided to the
          User in any other manner or form, which is hosted at{" "}
          <a
            href="http://mype.in/privacy"
            target="_blank"
            rel="noMyPeer noreferrer"
          >
            www.mype.in/privacy
          </a>{" "}
          (“Privacy Policy”).
          
        </li>
        <li>
          Terms and conditions stipulated by Server Providers (including Banks),
          which are specifically applicable to the Service(s) availed by You.
        </li>
      </ul>
      <h2 style={{ marginTop: "20px" }}>Definitions</h2>
      <p style={{ fontSize: "1.1rem" }}>
        Unless the context otherwise requires, the following capitalized words
        shall have the meaning assigned to them below.
      </p>
      <ul style={{ fontSize: "1.1rem" }}>
        <li>
          <strong>“Admin Verified”</strong> refers to the approval provided by
          the administrator at MyPe the User to use a particular Service or set
          of Services subject to the User information submitted, KYC, risk
          assessment and execution of any other controls or protocols that may
          be required to verify and authenticate the User in accordance with
          applicable laws, including but not limited to the RBI Guidelines.
        </li>
        <li>
          <strong>“Acquiring Bank”</strong> shall mean any bank which receives
          funds as sent by the User to the Customer.
        </li>
        <li>
          <strong>“Bank”</strong> refers to any bank or financial institutions
          that are licensed and regulated by the Reserve Bank of India (“RBI”),
          which MyPe has partnered with towards offering the Services. It is
          hereby clarified that the term “Bank” also includes any sponsor banks
          that MyPe has partnered with, by establishing software protocols and
          direct integrations via API.
        </li>
        <li>
          <strong>“Business Days”</strong> means any day on which banks are MyPe
          for business in Mumbai, New Delhi and Bengaluru, India.
        </li>
        <li>
          <strong>“Business Current Account”</strong> refers to a bank account
          that is linked by the User to the MyPe Platform for settlement of
          proceeds. The Business Current Account can either be MyPeed afresh
          with any Bank and linked to the MyPe Platform for settlements or by
          completing KYC verification and linking an existing bank account to
          the MyPe Platform. Usage of the Business Current Account by any User
          is subject to the terms stipulated by the concerned bank.
        </li>
        <li>
          <strong>“Card Payment Network”</strong> includes Visa, Mastercard, the
          National Payments Corporation of India, American Express, Diner’s Club
          and any other card payment network designated as an authorized payment
          system as per RBI Guidelines.
        </li>
        <li>
          <strong>“Card Payment Network Rules”</strong> refer to the written
          rules, regulations, releases, guidelines, processes, interpretations
          and other requirements (whether contractual or otherwise) imposed and
          adopted by the Card Payment Network.
        </li>
        <li>
          <strong>“Confirmation of Delivery”</strong> refers to the confirmation
          by the User provided to MyPe confirming delivery of goods or services
          to the Customer, which also authorizes MyPe to settle funds collected
          in the Escrow Account to the Business Current Account belonging to the
          User in accordance with the RBI Guidelines. This authorization will
          remain in full force and effect until specifically terminated by the
          User in writing.
        </li>
        <li>
          <strong>“Customer”</strong> shall mean any individual or entity that
          transacts with the User resulting in transference of funds to the
          User.
        </li>
        <li>
          <strong>“Escrow Account”</strong> shall refer to the nodal account or
          escrow account maintained by MyPe with the Bank in accordance with the
          RBI Guidelines.
        </li>
        <li>
          <strong>“Know Your Customer” or “KYC”</strong> shall mean the various
          norms, orders, rules, regulations, laws, and statutes as per
          applicable laws, including the RBI Guidelines issued from time to time
          according to which MyPe procures personal identification details from
          the User desiring to access/use the MyPe Platform.
        </li>
        <li>
          <strong>“MyPe Pay” or “User Account”</strong> refers to the online
          account MyPeed by the User in accordance with the onboarding
          requirements stipulated on the MyPe Platform.
        </li>
        <li>
          <strong>“Payee”</strong> means any person to whom a payment is made,
          using the Services, (and the term includes a User who uses the
          Services to receive payment).
        </li>
        <li>
          <strong>“Payer”</strong> means any person who makes a payment, using
          the Services (and the term includes a User who uses the Services to
          make payment).
        </li>
        <li>
          <strong>“Prohibited Business”</strong> refers to any businesses,
          business activities, or business practices that are expressly
          prohibited by Us and/or our Service Providers, deemed
          illegal/unethical by the applicable laws, regulations, or commonly
          accepted business standards, and includes the business activities.
        </li>
        <li>
          <strong>“RBI Guidelines”</strong> refers to the extant rules,
          regulations, orders, directions, notifications and guidelines issued
          by RBI, including but not limited to - the Payment & Settlement
          Systems Act, 2007, the Guidelines on Regulation of Payment Aggregators
          and Payment Gateways dated March 17, 2020 and the Master Directions
          for Know Your Customer (KYC), 2016, as amended from time to time.
        </li>
        <li>
          <strong>“Service(s)”</strong> means and includes the following
          services provided by MyPe to the User at the request of the User:
          <ul>
            <li>MyPe Pay for payment collections;</li>
            <li>Payment Gateway;</li>
            <li>Automated Accounting;</li>
            <li>Multi-Account Connect;</li>
            <li>Tax Filing;</li>
            <li>Co-branded prepaid cards;</li>
            <li>Business Loans;</li>
            <li>Apps - MyPe Tally, Business Connect, MyPe Payroll etc.; and</li>
            <li>
              Includes any other products/services offered by Us on the MyPe
              Platform, from time to time.
            </li>
          </ul>
        </li>
        <li>
          <strong>“Service Provider”</strong> refers to any entity, association
          of persons, facility provider, lender, card issuing institution, Bank,
          card processor, clearing house networks whose facilities or services
          are utilized in the provision of the Services.
        </li>
        <li>
          <strong>“Transaction”</strong> means a payment instruction that
          results in the successful transfer of funds (a) from a User to a
          Payee; or (b) from a Payer to a User, as the context requires.
        </li>
        <li>
          <strong>“User”, “You”, “Your” and “Yourself”</strong>, as the context
          requires, refers to and include any natural person or legal person
          (sole proprietor, partnership firm, company, LLPs, trusts, societies
          or HUFs) that registers on the MyPe Platform and/or transacts or
          avails Services. The term ‘User’ shall also include such personnel of
          the User (including but not limited to any current or former
          directors, officers or employees of the User) who affirm, accept, and
          comply with these Terms, on behalf of the User and who is granted
          rights or limited rights, as the case may be, to access/use the MyPe
          Platform.
        </li>
      </ul>
      <h6>
        <strong>Eligibility</strong>
      </h6>

      <p>
        The MyPe Platform must be accessed/used only by persons who can enter
        into legally binding contracts under the Indian Contract Act, 1872 by
        virtue of being ‘competent to contract’ within the meaning of Section 11
        of the Indian Contract Act, 1872. The MyPe Platform may be accessed and
        used by Users solely for their legitimate business requirements.
      </p>
      <h6>
        <strong>Registration and Access</strong>
      </h6>
      <p>
        To access and use the MyPe Platform and Services, You must have a User
        Account registered on the MyPe Platform. The User Account is provided to
        you by the Company in partnership with the Service Providers, subject to
        applicable laws (including the RBI Guidelines). You will provide the
        Company and the Service Providers (as applicable) with all documents and
        information required to carry out KYC in accordance with the RBI
        Guidelines. You must provide true, accurate, current and complete
        information at all times, failing which, MyPe may at any time reject
        Your registration and terminate your right to use or access the MyPe
        Platform and/or Services. For the purpose of conducting KYC on the User,
        MyPe has the right to seek the self-attested copies of the documents, as
        submitted during/post the registration process. You understand and
        unconditionally agree that even though You may be allowed to execute
        transactions on the MyPe Platform, the funds shall not be settled to
        Your account if there are any KYC obligations pending on Your part, in
        accordance with the RBI Guidelines. Further, in the event of non-
        completion of Your KYC obligations or breach/suspicion of breach of the
        Terms, to the satisfaction of MyPe, We reserve the right to not release
        the settlement amounts to You and eventually reverse the funds to the
        account from where such payment originated. When You register on the
        MyPe Platform, a User Account will be created based on the information
        You have provided. You are advised to keep the User credentials in
        confidence as you will be solely responsible for anything that happens
        through Your User Account. You will ensure that the User Account details
        provided and/or updated are correct and complete at all times. You shall
        inform MyPe of any change in the User email address, mobile number,
        address, change in authorization, control or legal status or cessation
        of the User’s business by writing to Us within 30 (thirty) Business Days
        of such change or occurrence. You shall be responsible at all times for
        maintaining the safety and confidentiality of the username, password and
        any other information pertaining to the User Account and for preventing
        unauthorized access to the User Account and devices. MyPe will not be
        liable for any mistake or misuse of User Account by either the User or
        by any person authorized by the User, or by any person gaining access to
        the Services through the User Account. It shall be Your responsibility
        to review these Terms periodically for updates / changes. Your continued
        use of the MyPe Platform following any amendments of these Terms is
        construed as deemed acceptance of such amendments. Subject to the User’s
        continued compliance with these Terms, MyPe grants the User a personal,
        non-exclusive, non-transferable, limited privilege to access and use the
        MyPe Platform. You will use the MyPe Platform only for lawful business
        activities and will not carry out any activity which is banned, illegal
        or immoral, or in any manner facilitate furtherance of any such
        activities which constitutes a violation of any law or regulation,
        including but not limited to the RBI Guidelines. It is hereby clarified
        that any funds lying in the Business Current Account is held by the
        relevant Bank and shall be governed by the terms framed by the said
        Bank. Funds in the Business Current Account shall belong to the account
        holder registered on the Bank’s records and will be subject to
        applicable Bank charges. You will be responsible and shall indemnify
        MyPe against any liability, costs or damages arising in connection with
        (a) the User providing false, incorrect or misleading, information; and
        (b) compromise of the User Account credentials. MyPe reserves the right
        to refuse access to the MyPe Platform, terminate accounts, remove or
        edit content at any time without notice to the User concerned if these
        Terms are violated. The User unconditionally authorizes MyPe to generate
        Lightweight Directory Access Protocol (LDAP), the User credentials and
        subscribe to the API's on the sponsor Bank’s web portal or software
        platform on behalf of the User to facilitate the Services. The User
        authorizes MyPe to service the User's Business Current Account on their
        behalf with regards to queries and complaints raised by the User and/or
        third party for facilitation of the Services including authorization for
        raising of queries with the Banks on their behalf.
      </p>

      <h6>
        <strong>Aadhar Offline e-KYC Verification</strong>
      </h6>
      <p>
        There are a number of options for you to complete KYC on the MyPe
        Platform. If you proceed to register on the MyPe Platform using Aadhar
        Offline e-KYC, You (the Aadhaar holder), hereby irrevocably:
      </p>

      <ul>
        <li>
          place a request to MyPe and Perfios Software Solutions Private Limited
          (“Perfios”) ( erstwhile known as Karza Technologies Private Limited or
          “Karza”) to access Your Aadhaar Information (defined below) from UIDAI
          to fetch and verify information regarding Aadhaar Number, Aadhaar XML,
          Virtual ID, e-Aadhaar, Masked Aadhaar, Aadhaar details, demographic
          information, identity information, Aadhaar registered mobile number,
          face authentication details and/or biometric information (collectively
          referred to as “Aadhaar Information”).
        </li>
        <li>
          explicitly authorize MyPe and Perfios to fetch Your Aadhaar
          Information from UIDAI to make it available to MyPe and/or any third
          party, as may be required for completing KYC verification.
        </li>
        <li>
          agree to take all the necessary actions required for the purpose of
          authenticating and verifying Your Aadhaar Information.
        </li>
        <li>
          give a valid, binding, irrevocable and explicit authorization and
          consent as may be required under applicable laws, rules, regulations
          and guidelines for availing the Aadhaar API services of Perfios
          including, but not limited to the transmission and storage of Your
          Aadhaar Information by MyPe and Perfios.
        </li>
        <li>
          understand and agree that the Consent has been submitted by You
          voluntarily and without any coercion from MyPe or Perfios or any other
          party.
        </li>
        <li>
          fully understand and accept sole and complete responsibility for any
          issues, legal suits, damages, losses, penalties, fines or liabilities
          (“Losses”) arising out of Your sharing of Aadhaar Information and
          authorizing MyPe or Perfios for fetching Your Aadhar Information and
          that You will fully hold harmless MyPe and Perfios, its
          representatives, employees and directors for any Losses arising out of
          such request and actions.
        </li>
        <li>
          Understand and agree that MyPe and Perfios do not store or retain any
          Aadhaar Information including the Aadhaar number belonging to You
          after it has processed Your request.
        </li>
      </ul>
      <h6>
        <strong>Fees and Taxes</strong>
      </h6>
      <p>
        Unless otherwise mutually agreed in writing, You will be charged fees on
        a prepaid basis depending (a) on the Services You have requested or
        availed; and (b) the pricing/subscription plan that You have opted for
        (please refer to https://MyPe.money/pricing for more details) on the
        MyPe Platform (“Fee”). The subscription plans will be charged to You on
        a 30-day monthly or 360-day yearly policy. Your consumption of the
        Services will continue to be governed by the terms and Fee stipulated
        for the said subscription plan until You expressly upgrade or opt out of
        that subscription plan. MyPe will not make refunds of any amounts
        charged as Fee unless it is otherwise agreed as per these Terms. You
        shall bear all taxes in connection with the levy of all Fee in
        accordance with applicable laws, including but not limited to – goods
        and services tax.
      </p>
      <h6>
        <strong>Data, Audit & Security</strong>
      </h6>
      <p>
        MyPe may monitor all Transactions to flag and prevent high-risk
        practices and fraudulent transactions. It is understood that MyPe may
        also engage Service Providers to assist in these efforts. In the event
        of any suspicious or unusual activity being carried out through the User
        Account, such account may be temporarily or permanently suspended. The
        User and MyPe each represents and covenants to the other that: (a) it
        does not store card information or any related data within its database
        or any servers accessed by it, except for limited lawful purposes,
        compliant with the RBI Guidelines; (b) it will adhere to the data
        security protocols prescribed under the RBI Guidelines, including the
        procedures for incident management and reporting; and (c) its systems
        and infrastructure are compliant with the Payment Card Industry-Data
        Security Standard (“PCI-DSS”). The User further represents and covenants
        to MyPe that its systems and infrastructure are compliant with the
        Payment Application-Data Security Standard (“PA-DSS”), as applicable.
        MyPe at its sole and absolute discretion, without providing prior
        notice, may conduct inspections, risk and security assessments and
        audits on the User and may impose additional conditions/restrictions on
        the User Account, including but not limited to: (a) establishing a
        reasonable reserve amount to cover potential Chargebacks (defined below)
        and related fees; and (b) withholding Services and directing the User to
        upgrade its infrastructure in compliance with applicable laws. The User
        will not sell, provide, exchange, or otherwise disclose to third parties
        or use themselves (other than for the purpose of completing a
        transaction, or as specifically required by law) any personal
        information about any third party (whether its Customer or otherwise),
        including the financial details and any personal identification
        information, without obtaining the prior written consent of such third
        party. We employ the use of cookies on the MyPe Platform. Most
        interactive websites use cookies to enable retrieval of the User details
        for each visit. Cookies are used in some areas of the MyPe Platform to
        enable and enhance the functionality and ease of use for the Users. By
        using the MyPe Platform, the User consents to the use of cookies in
        accordance with the Privacy Policy. Some of the Service Providers may
        also use cookies.
      </p>

      <h6>
        <strong>Content</strong>
      </h6>

      <p>
        Unless specifically permitted by the User, usage of the Services does
        not grant MyPe the license to use, reproduce, adapt, modify, publish or
        distribute the content created and/or stored in the User Account.
        Notwithstanding the above, the User grants MyPe permission to access,
        copy, distribute, store, transmit, reformat, or disclose the data
        /content of the User Account for the purpose of providing the Services
        requested/availed by the User. The User may transmit or publish content
        created by using any of the Services or otherwise. However, the User
        shall be solely responsible for such content and the consequences of its
        transmission or publication. Any content that the User may receive from
        third-parties while using the Services, is provided to the User ‘AS IS’
        for their information and personal use only and thus agree not to use,
        copy, reproduce, distribute, transmit, broadcast, display, sell, license
        or otherwise exploit such content for any purpose, without the express
        written consent of the person who owns the rights to such content. We
        are not responsible if information made available on the MyPe Platform
        is not accurate, complete or correct. The material on the MyPe Platform
        is provided for general information only and should not be relied upon
        or used as the sole basis for making decisions without consulting
        primary, more accurate, more complete or more timely sources of
        information. Any reliance on the material on the MyPe Platform is at the
        User’s own risk. The MyPe Platform may contain certain historical
        information. Historical information, necessarily, is not current and is
        provided for the User’s reference only. We reserve the right to modify
        the contents of the MyPe Platform at any time, but we have no obligation
        to update any information on the MyPe Platform. The User agrees that it
        is the User’s responsibility to monitor changes on the MyPe Platform.
      </p>

      <h6>
        <strong>Third-Party Links</strong>
      </h6>

      <p>
        The MyPe Platform may refer to or may contain, links to third-party
        websites, applications, services and resources but it does not mean that
        We are endorsing such channels. We provide these links only as a
        convenience to the User to avail certain services, the Company makes no
        representation or warranty of any kind regarding the accuracy,
        reliability, effectiveness, or correctness of any aspect of any
        third-party services, and consequently, the Company is not responsible
        for the content, products or services that are available from
        third-party services. The User is responsible for reading and
        understanding the terms and conditions and privacy policy that applies
        to the User’s use of any third-party services, and the User acknowledges
        sole responsibility and assumes all risks arising from User’s use of any
        third-party services.
      </p>

      <h6>
        <strong>Intellectual Property Rights</strong>
      </h6>

      <p>
        All copyrights, patents, trade secrets, trademarks, service marks, trade
        names, moral rights and other intellectual property and proprietary
        rights (“IPR”) in the MyPe Platform and Services shall remain the sole
        and exclusive property of MyPe and its licensors, as applicable. The IPR
        may not be copied, reproduced, distributed, transmitted, broadcast,
        displayed, sold, licensed, uploaded, or otherwise exploited without the
        prior written consent of MyPe.
      </p>
      <h6>
        <strong>Relationship:</strong>
      </h6>

      <p>
        You understand and agree that nothing contained in these Terms shall be
        deemed or construed as creating a partnership or joint venture between
        the User and MyPe or deemed to cause any party to be responsible in any
        way for the debts and obligations of the other party. You will not
        describe (whether online or otherwise) Yourself as agent or
        representative of MyPe or make any representations to any User or any
        third party or give any warranties which may require MyPe or Service
        Provider to undertake to or be liable for, whether directly or
        indirectly, any obligation and/or responsibility to the User or any
        third party.
      </p>
      <h6>
        <strong>Confidentiality</strong>
      </h6>

      <p>
        The User agrees not to disclose or attempt to use or personally benefit
        from any non-public information that it may learn or discover on the
        Website or through the Services. This obligation shall continue until
        such time as the non-public information has become publicly known
        through any action of the User. If the User is compelled by order of a
        court or other governmental or legal body (or have notice that such an
        order is being sought) to divulge any such non-public information, the
        User agrees to promptly and diligently notify MyPe and cooperate fully
        with MyPe in protecting such information to the extent possible under
        applicable law. MyPe may access, preserve and disclose any of User’s
        information if such information is required for the facilitation of any
        Services to the User as per the User’s request, or to ensure compliance
        with applicable law, or if We believe in good faith that it is
        reasonably necessary to (i) respond to claims asserted against MyPe or
        to comply with legal process, (ii) for fraud prevention, risk
        investigation, User support, product development and de-bugging
        purposes, or (iii) protect the rights, property or safety of MyPe, its
        Users or members of the public.
      </p>
      <h6>
        <strong>Disclaimer of Warranties & Limitation of Liability</strong>
      </h6>
      <p>
        In processing Transactions, MyPe shall be entitled to rely upon all
        electronic communications, orders or messages sent to MyPe through the
        Service Providers including and to the extent this is in compliance with
        the processing mechanism of the Service Providers and any applicable
        laws and the RBI Guidelines. MyPe shall not be obligated to verify or
        make further inquiry into the identity of the sender, or the message
        integrity, of any communications, orders or messages. MyPe will
        endeavour to ensure that access to and availability of the Services
        remains uninterrupted and error free. However, access to the application
        may occasionally be suspended or restricted to allow for repairs,
        maintenance, or for the introduction of new facilities and Services and
        in such cases MyPe shall not be liable for any disruption of any
        Services. We reserve the right to determine the timing and content of
        software updates, which may be automatically downloaded and installed by
        an application without prior notice to the User anytime. The Services
        are provided on an as-is and as-available basis. MyPe expressly
        disclaims all warranties of any kind, whether express or implied,
        including, but not limited to, the implied warranties of merchantability
        and fitness for a particular purpose. MyPe makes no warranty that the
        Services will be uninterrupted, timely, secure, or error free. To the
        maximum extent permitted by law, the entire risk arising out of the
        User’s access of and use of the Website and Services, remains with the
        User. A separate limitation over liability shall be applicable to each
        of the Services. MyPe shall not be liable for any breach of these Terms
        due to any force-majeure event that prevents or delays performance of
        its obligations under these Terms such as lockdown, act of god, fire,
        lightning, explosion, flood, adverse weather conditions, power failures,
        failure in any communication systems, equipment breakdown, strikes,
        lock-out or any other cause beyond the control of MyPe.
      </p>
      <h6>
        <strong>Indemnity</strong>
      </h6>
      <p>
        The User shall keep MyPe indemnified from and against any and all
        liability (including but not limited to liabilities, judgments, damages,
        losses, claims, costs and expenses,) or any other loss that may occur,
        arising from or relating to any claim, suit or proceeding brought
        against MyPe by another User/ Service Provider/ third party for reasons
        including, but not limited (i) a breach, non-performance, non-
        compliance or inadequate performance by the User of any of the terms,
        conditions, representations, obligations or warranties made by him; (ii)
        any acts, errors, misrepresentations, willful misconduct or negligence
        of the User, or his employees, subcontractors and agents in performance
        of their obligations under these Terms.
      </p>
      <h6>
        <strong>Communication Policy</strong>
      </h6>
      <p>
        As part of User’s use of the Services, User may receive notifications,
        offers, discounts and general information from MyPe via text messages or
        by emails, for the purpose of collecting feedback regarding User’s
        services. The User understands that receipt of non-service-related
        communications can be deactivated from User Account settings or by
        sending an email to letstalk@bankMyPe.co and by ab-initio consents to
        receive such communications. The User acknowledges that the SMS service
        provided by MyPe is an additional facility provided for the User’s
        convenience and that it may be susceptible to error, omission and/ or
        inaccuracy.
      </p>
      <h6>
        <strong>Customer Complaints</strong>
      </h6>

      <p>
        If We receive any general complaint against the User in connection with
        the use of the MyPe Platform, We will forward the complaint to User’s
        registered email address. The User must respond to the complaint
        directly within 36 (thirty-six) hours of receiving the complaint as
        forwarded by MyPe and copy MyPe in its communications. If the User does
        not respond to the complainant within 36 (thirty-six) hours from the
        date of MyPe’s email to the User, We may at our sole discretion opt to
        disclose the User’s contact information to the complainant for enabling
        the complainant to take legal action against the User. The User
        understands that its failure to respond to the forwarded complaint
        within the 36 (thirty- six) hours’ time limit will be construed as the
        User’s consent to disclosure of the User’s name and contact information
        by MyPe. Notwithstanding the foregoing, the User acknowledges and agrees
        that (a) MyPe can disclose any information relating to the User if it
        deems in its sole discretion that such disclosure is required in the
        interest of justice; and (b) MyPe can withhold or appropriate any
        settlements due to the Merchants to ensure compliance with applicable
        laws on reasonable suspicion. It is understood that a Transaction is
        solely between the User and its Customer and MyPe does not provide any
        guarantees or warranties, or delivery level commitment with respect to
        the User. Use of the MyPe Platform by a User in no way represents any
        endorsement by MyPe of any User. MyPe will facilitate the mediation of
        any disputes between the User, Customer, and Payment Participant
        concerning the Service(s) offered by MyPe through its platform.
      </p>
      <h6>
        <strong>Suspension and Termination</strong>
      </h6>
      <p>
        In the event (a) the User breaches these Terms; (b) the User engages in
        or is suspected of engaging in any illegal, fraudulent or abusive
        activity; or (c) the User provides any information that is untrue,
        inaccurate, not current or incomplete or We have reasonable grounds to
        suspect that such information is untrue, inaccurate, not current or
        incomplete, or not in accordance with these Terms, MyPe reserves the
        right, to suspend/terminate such User’s access to any or all of the MyPe
        Platform and Services without notice, and this will be without prejudice
        to MyPe’s right to exercise any other remedy available to it under
        applicable law. In the event MyPe identifies any suspicious or unusual
        activity being carried out by the User on the MyPe Platform, MyPe at its
        discretion may temporarily or permanently suspend the User’s access to
        the MyPe Platform and/or take any such actions necessary to mitigate
        risk including but not limited to withholding of funds or refunding the
        funds to the source from where such payment originated.
      </p>

      <h6>
        <strong>Prohibited Services</strong>
      </h6>
      <p>
        The User acknowledges and agrees that the Services shall not be utilized
        in connection with or to enable/meet the objective(s) of, any Prohibited
        Services. The User's utilization of the Services is contingent upon the
        User refraining from the Prohibited Services as stipulated by the
        Service Providers specific to their offerings. If MyPe reasonably
        suspects that the User is accessing/using the Services in connection
        with a Prohibited Service, the User agrees that MyPe may take such
        action as it deems to mitigate the risk and/or likelihood of risk from
        the said Prohibited Service, including but not limited to, reporting the
        User, blocking the User’s funds and refunding the funds collected by the
        User to source.
      </p>

      <h6>
        <strong>Governing Law, Jurisdiction and Disputes</strong>
      </h6>
      <h6>
        <strong>
          MyPe Pay can be used to make payments and receive collections for any
          Transaction arising out of the normal course of business of the User.
        </strong>
      </h6>
      <p>
        Without prejudice to the provisions mentioned below, these Terms are
        subject to the laws of India and any disputes arising out of or in
        connection with the Terms will be subject to the exclusive jurisdiction
        of the competent courts at Bengaluru, India. MyPe and the User
        acknowledge and agree that, in the event of any dispute/claim between
        arising out of or in connection with the Terms: (a) the transaction logs
        maintained by MyPe will be the only source of data to verify the
        accuracy of such transactions; (b) such logs will be fully binding as
        evidence for the purposes of adjudicating the said dispute/claim; and
        (c) the said dispute/claim shall be settled by binding arbitration in
        accordance with the Arbitration and Conciliation (Amendment) Act, 1996
        and the rules of the Conciliation and Arbitration Centre, Bengaluru as
        amended from time to time and the exclusive seat of arbitration shall be
        at Bengaluru, India. If any part of these Terms is determined to be
        invalid or unenforceable pursuant to applicable law including, but not
        limited to, the warranty disclaimers and liability limitations set forth
        herein, then the invalid or unenforceable provision will be deemed
        superseded by a valid, enforceable provision that most closely matches
        the intent of the original provision and the remainder of the Terms
        shall continue in effect.
      </p>

      <h2 style={{ marginTop: "20px" }}>PART B (Service Terms & Conditions)</h2>
      <p>
        The User represents and agrees that usage of the Services mentioned
        under this Part B will inherently subject the User to the General Terms
        governing use of the MyPe Platform as set out under Part A.
      </p>
      <h6>
        <strong>MyPe Pay</strong>
      </h6>
      <p>
        <h6>
          <strong>
            MyPe Pay can be used to make payments and receive collections for
            any Transaction arising out of the normal course of business of the
            User.
          </strong>
        </h6>
      </p>
      <ul>
        <li>
          By proceeding to use MyPe Pay, the User (a) signifies its consent to
          be bound by these Terms; (b) will be contracting with MyPe; and (c)
          acknowledges and agrees these Terms will constitute the User’s binding
          obligations, with MyPe while using MyPe Pay.
        </li>
        <li>
          Eligibility:- To use MyPe Pay, You must: (i) be a tax resident of
          India; and (ii) be Admin Verified and registered to use MyPe Pay on
          the MyPe Platform.
        </li>
        <li>
          Rights and Obligations:- No interest is payable by MyPe on the amounts
          maintained by the User in MyPe Pay. A User is permitted to operate
          only 1 (one) MyPe Pay account. Any amounts collected by the User into
          MyPe Pay using Payment Gateway Services (as defined below) will be
          subject to the provisions in these Terms governing Payment Gateway
          Services. The availability of balance on MyPe Pay to the User will be
          subject to receipt of funds into the Escrow Account and User’s
          continued compliance with these Terms and the RBI Guidelines at all
          times. Subject to fulfilling the KYC requirements, the User may link
          its Business Current Account to its MyPe Pay in order to settle
          Transaction proceeds in accordance with the RBI Guidelines. Once
          linked, the Transaction amount (minus Fees, as applicable) will be
          settled to the User’s Business Current Account from MyPe Pay within
          T+1 days where ‘T’ is the date of Confirmation of Delivery by the User
          to MyPe. The User shall be solely responsible for providing the
          updated and correct details of their Business Current Account so
          linked with the MyPe Pay Account. Notwithstanding anything, the User
          acknowledges and agrees that where MyPe has no control over incoming
          funds and its delay thereof, MyPe will transfer the funds to the
          merchant in accordance with the RBI Guidelines. The User understands
          and unconditionally acknowledges that even if the User is allowed to
          receive payment using MyPe Pay, funds from such transactions will not
          be settled to the User’s MyPe Pay / Business Current Account or any
          other account until KYC obligations are completed by the User in
          accordance with the RBI Guidelines. If the User continues to default
          on KYC obligations, as mentioned above, MyPe at its sole and absolute
          discretion may refrain from releasing the settlement amounts to the
          User and accordingly reverse the funds to the source from where such
          payment originated.
        </li>
        <li>
          Usage:- The User authorizes MyPe to rely on and act upon any
          instructions provided by the User in respect of its MyPe Pay account
          and such instructions shall be conclusively presumed for MyPe’s
          benefit to be duly authorized by and legally binding on the User. The
          User will access and operate its MyPe Pay account solely for the
          debits and credits permitted under the RBI Guidelines. MyPe shall be
          entitled to require any instruction/document in any form to be
          authenticated by the User of any password, identification code or
          authentication test as may be specified by MyPe from time to time and
          the User shall ensure the secrecy and security of such password, code
          or test. All payments made by the User will be subject to the
          two-factor authentication system envisaged under the RBI Guidelines.
          The User acknowledges that there are inherent risks involved in
          sending the instructions/communications/documents to MyPe via
          electronic communications/instructions and accordingly agrees and
          confirms that all risks in this regard, shall be fully borne by the
          User.
        </li>
        <li>
          Termination:- MyPe reserves the right to suspend/discontinue MyPe Pay
          for the User for any reason whatsoever. This includes the following
          grounds, based on suspicion or otherwise:
          <ul>
            <li>Violation of the RBI Guidelines;</li>
            <li>Violation of any of these Terms;</li>
            <li>
              Discrepancy in the information and KYC documentation provided by
              the User;
            </li>
            <li>
              To prevent potential fraud, sabotage, willful destruction or
              threat to national security; or
            </li>
            <li>
              Where MyPe in its sole opinion and discretion believes that
              cessation/ suspension of the MyPe Pay is necessary to meet the
              ends of justice.
            </li>
          </ul>
        </li>
      </ul>
      <h6>
        <strong>Payment Gateway</strong>
      </h6>

      <p>
        The MyPe Platform enables the User to accept payments from multiple
        Customer sources including credit/debit cards, net-banking, e-wallets
        and UPI ( “Payment Gateway Services”) subject to continued compliance
        with these Terms.
      </p>
      <ul>
        <li>Authorization</li>
        <p>
          The User understands that the Payment Gateway Services are provided on
          the MyPe Platform by integrating with various Service Providers in
          order to provide a single solution for all payment acceptance. The
          User authorizes MyPe to hold, receive, disburse and settle funds on,
          the User’s behalf subject to these Terms. The User authorization
          permits MyPe to generate an electronic funds transfer between the
          payment system providers and the Escrow Account to process each
          Transaction. The User acknowledges that its continued use of the
          Payment Gateway Services will be subject to its compliance with the
          RBI Guidelines. The authorizations provided hereunder by the User will
          remain in full force and effect until the User Account is closed or
          terminated in accordance with the provisions contained hereunder. All
          risk, loss or liability associated with delivery undertaken by the
          User to its Customers will be solely and absolutely attributable the
          User. Also, all disputes regarding quality, merchantability,
          non-delivery, delay in delivery or otherwise will be directly handled
          between the User and the Customer without any reference to MyPe as a
          party to such disputes.
        </p>
        <li>Card Payment Network Rules:</li>
        <p>
          The Card Payment Networks have provided the infrastructure and
          processes to enable Transaction authorization. The Card Payment
          Network requires the User to comply with the Card Payment Network
          Rules. The Card Payment Network reserves the right to amend their
          guidelines, rules and regulations. We may be required to amend modify
          or change these terms of use pursuant to amendments to the Card
          Payment Network Rules and such amendments, if any, shall be deemed to
          be binding on the Users with immediate effect. Notwithstanding our
          assistance in understanding the Card Payment Network Rules, the User
          expressly acknowledges and agrees that the User is assuming the risk
          of compliance with all provisions of the Card Payment Network Rules,
          regardless of whether the User is aware of or have access to those
          provisions. RuPay, MasterCard, Visa and American Express make excerpts
          of their respective rules available on their internet sites. In the
          event that, the User’s non-compliance of Card Payment Network Rules,
          results in any fines, penalties or other amounts being levied on or
          demanded of Us by a Card Payment Network, then without prejudice to
          Our other rights hereunder, the User shall forthwith reimburse Us in
          an amount equal to the fines, penalties or other amount so levied or
          demanded or spent by Us in any manner in relation to such fines,
          penalties and levies. If the User fails to comply with the User’s
          obligations towards the Card Payment Network, We may suspend
          settlement or suspend/terminate the Services or any part thereof
          forthwith or freeze and appropriate incoming funds to the User
          Account.
        </p>
        <li>Settlement:</li>
        <p>
          All Transactions settled to the User shall be subject to the Fees
          payable to MyPe. The availability of balance on MyPe Pay to the User
          will be subject to receipt of funds into the Escrow Account and User’s
          continued compliance with the RBI Guidelines at all times. Subject to
          fulfilling the KYC requirements, the User may link its Business
          Current Account to its MyPe Pay in order to settle Transaction
          proceeds in accordance with the RBI Guidelines. Once linked, the
          Transaction amount (minus the Fees, as applicable) will be settled to
          the User’s Business Current Account from MyPe Pay within T+1 days
          where ‘T’ is the date of Confirmation of Delivery by the User to MyPe.
          The User shall be solely responsible for providing the updated and
          correct details of their Business Current Account so linked with the
          MyPe Pay Account. Notwithstanding anything, the User acknowledges and
          agrees that where MyPe has no control over incoming funds and its
          delay thereof, MyPe will transfer the funds to the merchant in
          accordance with the RBI Guidelines. If the User requests, MyPe at its
          sole discretion may provide early settlement services with respect of
          the receivables of the User, to the MyPe Pay account or Business
          Current Account, as applicable at such additional Fees that MyPe
          informs to the User in writing. The User understands and
          unconditionally acknowledges that even if the User is allowed to
          receive payment using MyPe Pay, funds from such transactions will not
          be settled to the User’s MyPe Pay / Business Current Account or any
          other account until KYC obligations are completed by the User in
          accordance with the RBI Guidelines. If the User continues to default
          on KYC obligations, as mentioned above, MyPe at its sole and absolute
          discretion may refrain from releasing the settlement amounts to the
          User and accordingly reverse the funds to the source from where such
          payment originated.
        </p>
        <li>Transaction Dispute:</li>
        <p>
          The User shall adhere to the refund policy stipulated by the User’s
          website, as reviewed and approved by MyPe (as applicable). The User
          agrees that transactions involving the Card Payment Networks may be
          disputed at any time up to 180 (one hundred eighty) days, from the
          date of transaction by the Customer as per the Card Payment Network
          Rules. Disputes resolved in favour of the Customer may result in
          reversal of payments to the Customers (“Chargeback”). In the event of
          rejection/suspension of payments to the User, Chargebacks, refunds
          and/or any other dispute relating to the Transactions contemplated
          under these Terms ("Transaction Dispute") on any grounds whatsoever,
          MyPe will forthwith notify the User of the same. On such notification
          the User will conduct an internal review of such matter and will,
          within the time frame prescribed by MyPe in the notification, revert
          to Us in writing either: requesting Us to refund ("Refund Request")
          the payment received by the Customer in respect of such Transaction
          Dispute ("Refund Monies"); or providing Us with a statement explaining
          how the Disputed Transaction is not warranted, together with all
          documentary evidence in support of contesting such Transaction
          Dispute. In the event that the User provides a Refund Request to Us or
          fails to contest such Transaction Dispute within the aforesaid
          timeframe prescribed by MyPe or contests Transaction Dispute without
          providing supporting documentation to the satisfaction of MyPe, the
          Service Provider and/or Card Payment Network and/ or issuing Bank,
          MyPe will be entitled to recover the Refund Monies from settlements
          subsequently made to the User Account. The User will be liable to make
          payment of the Refund Monies or part thereof which has not been
          returned to MyPe forthwith. It is hereby agreed and acknowledged by
          the Parties that the Fees charged by MyPe in respect of the
          Transaction Dispute will not be refunded, compensated or paid by Us to
          the User, Customer or any other person. Further, the Chargeback will
          be effected within 1 (one) week of the Transaction and maximum amount
          of the Chargeback payable by Us to the Customer will be the value of
          the transaction only. The User also acknowledges and agrees that MyPe
          may route the failed Chargeback/refund amounts back to the concerned
          customer outside the payment aggregator escrow account using payment
          mechanisms it deems fit, subject to ensuring compliance with
          applicable laws at all times.
        </p>
        <li>Fees and Charges</li>
        <p>
          The User acknowledges and agrees that the operation of the Payment
          Gateway Services includes the deduction of amounts owed for service
          fees and/or refund monies (if applicable) by the Service Provider
          offering Payment Gateway Services. Consequently, the amounts
          receivable by a User from a Customer for a Card Transaction will be
          reduced by the deducted fees and refunds.
        </p>
        <li>Miscellaneous</li>
        <p>
          In the event that the User acts in violation of applicable laws or
          breaches the Terms, the Service Provider offering Payment Gateway
          Services reserves the right to discontinue the availability of Payment
          Gateway Services to the User or take such other action as it deems fit
          in its sole discretion, without prior notice or intimation to the
          User. The User acknowledges and agrees to prominently display on its
          website(s) any policies, notices, disclaimers, warranties, and
          indemnities as may be requested in writing by the Service Provider
          offering Payment Gateway Services, at its sole discretion,
          periodically. Failure to adhere to these requirements concerning the
          specified website may result in such Service Provider exercising its
          discretion to cease the provision of Payment Gateway Services to the
          User. Should the User engage in Prohibited Services while utilizing
          the Services, the Service Provider offering Payment Gateway Services
          reserves the right, without prejudice to its other rights under the
          Terms, to instruct the User through MyPe to promptly discontinue the
          Prohibited Services. Additionally, such Service Provider may impose
          fines for such violations as it deems appropriate, at its sole
          discretion. This action is independent of such Service Provider’s
          right to cease providing Services to the User if MyPe has not
          terminated the User's access to the MyPe Platform and Services. The
          User acknowledges and agrees that the Service Provider offering
          Payment Gateway Services, during the User onboarding process and
          continuously thereafter, may request and acquire necessary details
          pertaining to the User. This includes but is not limited to,
          information regarding the User's turnover and any other details deemed
          essential by such Service Provider. The User agrees to incorporate the
          following elements on its website: (a) privacy statements; (b)
          identifiers that clearly associate the User’s website with the User's
          trade name for easy recognition; and (c) the User will display a
          statement encouraging its Customers to retain a copy of the
          transaction record for their records.
        </p>
      </ul>
      <h6>
        <strong>
          {" "}
          Multi-Account Connect, Automated Accounting and Tax filing
        </strong>
      </h6>
      <h6>Scope</h6>
      <p>
        This Service allows the User to track their Customer payments and
        transaction history across various banks under a unified dashboard
        (“Multi-Account Connect”) and equips the User with automatically
        generated book-keeping records and reports (“Automated Accounting”). You
        note and agree that MyPe is only acting as a technology aggregator for
        these purposes wherein the role of MyPe is limited to the extent of
        providing the MyPe Platform to You. MyPe has partnered with Service
        Providers who are authorized ERI and Application Service Providers (ASP)
        as per the Electronic Furnishing of Return of Income Scheme, 2007 and
        Goods and Services Tax Act, 2017 and may offer ERI Services and ASP
        Services in the near future. “ERI Services” include (i) income return
        filing and ancillary activities by adding you as a client on the Income
        Tax Department’s web portal to submit your Income-Tax Return (“ITR”),
        and retrieve information, such as your ITR-V, refund status, 26AS, etc.;
        (ii) easy filing of ITRs by automatically recognizing the data from the
        Form-16 uploaded by you; and (iii) manual filing of ITRs by allowing you
        to fill the requisite data in the ITR. “ASP Services” include taking the
        User’s raw data on sales and purchases and converting it into the GST
        returns (“GSTR”). These GSTRs will then be filed on behalf of the filer
        with GSTN via the GST Suvidha Provider. The User agrees to such terms
        stipulated by MyPe and its Service Providers, from time to time, if the
        User undertakes ERI Services and ASP Services from the MyPe Platform.
        The provision of the ERI services through the MyPe Platform and display
        of such information received from the Service Provider shall not in any
        manner constitute any recommendation, advice, opinion or any service
        being given by MyPe.
      </p>
      <h6>Exclusion of Liability</h6>
      <p>
        User understands, covenants and confirms that MyPe shall not be liable
        in case any unauthorized or incorrect instruction is executed by the
        User or concerned Service Provider resulting in any wrongful amount
        being debited or credited from the User. Consequently, MyPe expressly
        excludes any and all direct as well as indirect liability whatsoever
        which may arise in this regard.
      </p>
      <h6>Internet Frauds and Technology Risks</h6>
      <p>
        The internet is susceptible to a number of frauds, misuses, hacking and
        other actions, which could affect use of these Services. Whilst MyPe
        shall aim to provide security to prevent the same, it does not guarantee
        the User complete protection from such internet frauds, hacking and
        other actions, which could affect the use of these Services. There can
        be instances where the Services may require maintenance and during such
        time it may not be possible to process the request of the Users. This
        could result in delays in the processing of requisite instruction or
        failure in processing of instructions. The User understands and
        acknowledges that MyPe disclaims all and any liability, arising out of
        any failure or inability by MyPe to honour any Customer instruction.
      </p>
      <h6>Collection of User Data</h6>
      <p>
        The information collected for performing these Services (“Data”) will be
        based on the details submitted by the User. The User must provide true,
        accurate, current and complete Data at all times, failing which, the
        output from this Data will tend to be false, inaccurate, redundant or
        incomplete.
      </p>
      <h6>Use of User's Data</h6>
      <p>
        The information collected may be used for personalizing the User’s
        experience and better Our responses to match the User’s requirements,
        wherever possible, improve the MyPe Platform and/or application based on
        the information, their exact requirements, improving User experience
        based on feedback.
      </p>
      <h6>Request for Consent</h6>
      <p>
        The consent for the collection of Data and also for the subsequent use
        of the Data is deemed to be given by the User when the User decides to
        avail the Services.
      </p>
      <h6>Malicious Behavior</h6>
      <p>
        The MyPe Platform shall not allow any party and/or entity that steal
        data, secretly monitor or harm Users, or are otherwise malicious.
        Therefore, the following are expressly prohibited:
      </p>
      <li>
        viruses, trojan horses, malware, spyware or any other malicious
        software.
      </li>
      <li>
        apps/ websites that link to or facilitate the distribution or
        installation of malicious software.
      </li>
      <li>
        apps/ websites that introduce or exploit security vulnerabilities.
      </li>
      <li>
        apps/ websites that steal User’s authentication information (such as
        usernames or passwords) or that mimic other apps or websites to trick
        Users into disclosing personal or authentication Data.
      </li>
      <li>
        apps/ websites that install other apps on a device without the User’s
        prior consent.
      </li>
      <li>
        apps/ websites designed to secretly collect device usage, such as
        commercial spyware apps.
      </li>
      <h6>
        <strong>MyPe Credit</strong>
      </h6>
      <p>
        The terms and conditions hosted on the URL:
        https://MyPe.money/tnc-MyPecredit (“MyPe Credit Terms”) shall govern the
        credit and business loans facilities offered, by MyPe’s partners
        banks/financial institutions regulated by RBI, on the MyPe Platform as a
        part of the Services (hereinafter referred to as “MyPe Credit”). By
        proceeding to use MyPe Credit, the User signifies its consent to be
        bound by these MyPe Credit Terms which shall be read in conjunction with
        these Terms and the Privacy Policy.
      </p>
      <h6>
        <strong>Business Connect</strong>
      </h6>
      <p>
        The terms and conditions hosted on the URL:
        https://MyPe.money/tnc-MyPehub (“Business Connect Terms”) shall govern
        the marketplace offered on the MyPe Platform as a part of the Services
        (hereinafter referred to as “Business Connect”). By proceeding to use
        Business Connect, the User signifies its consent to be bound by the
        Business Connect Terms which shall be read in conjunction with these
        Terms and Privacy Policy.
      </p>
      <h6>
        <strong>MyPe Payroll</strong>
      </h6>
      <p>
        MyPe offers the Users payroll management soluislinktions on the MyPe
        Platform (“MyPe Payroll”). The terms and conditions hosted on the URL:
        https://MyPe.money/tnc-MyPepayroll (“MyPe Payroll Terms”) shall govern
        access and usage of MyPe Payroll and any services provided thereunder.
        By proceeding to use MyPe Payroll, the User signifies its consent to be
        bound by the MyPe Payroll Terms which shall be read in conjunction with
        these Terms at all times.
      </p>
    </div>
  );
};

export default TermsOfService;
