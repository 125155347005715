import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';

const OtpVerification = () => {
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const navigate = useNavigate();

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (value.match(/[0-9]/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (index < 5 && value) {
        document.getElementById(`otp-input-${index + 1}`).focus(); // Move focus to the next field
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus(); // Move focus to the previous field if backspace is pressed
    }
  };

  const handleSubmit = () => {
    const otpValue = otp.join('');
    console.log('Submitted OTP:', otpValue);
    // Here you can implement API call or validation for OTP
    if (otpValue === '123456') {
      navigate('/dashboard'); // Redirect to dashboard if OTP is correct
    } else {
      alert('Invalid OTP. Please try again.');
    }
  };

  return (
    <div
      style={{
        maxWidth: '700px',
        height:'360px',
        margin: 'auto',
        marginTop: '200px',
        padding: '40px',
        border: '1px solid #B59BCD',
        borderRadius: '10px',
        backgroundColor: '#fff',
        boxShadow: '0 4px 10px #B59BCD',
      }}
    >
      <h2 className="text-center fw-bold mb-4" style={{ fontSize: '2.2rem' }}>
        OTP Verification
      </h2>
      <p className="text-center" style={{ fontSize: '1.2rem' }}>
        Please enter the OTP sent to your email or mobile number.
      </p>

      <div className="otp-container mb-3" style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
        {otp.map((digit, index) => (
          <input
            key={index}
            id={`otp-input-${index}`}
            type="text"
            value={digit}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            maxLength="1"
            style={{
              width: '40px',
              height: '40px',
              fontSize: '20px',
              textAlign: 'center',
              borderRadius: '5px',
              border: '1px solid #ccc',
            }}
          />
        ))}
      </div>

      <Button
        onClick={handleSubmit}
        style={{
          background: '#752abf',
          borderRadius: '1.5rem',
          width: 'fit-content',
          border: 'none',
          marginTop: '30px',
        }}
        className="w-100"
      >
        Verify OTP
      </Button>
    </div>
  );
};

export default OtpVerification;
