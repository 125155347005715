import React from 'react';
import { Container, Row, Card } from 'react-bootstrap';

function Payments() {
  return (
    <Container fluid className="">
      <h2 className="text-center fw-bold mb-5">How It Works</h2>
      <Row className="justify-content-center">
        <Card
          className="text-center mb-4 border-0"
          style={{ width: '25rem', cursor: 'pointer', transition: 'background-color 0.3s ease' }}
          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#EFF5F5')} // Light color on hover
          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')} // Original color on leave
        >
          <Card.Body>
            <img src={"./assest/addbill.svg"} alt="Detail Icon" width={50} className="mb-3 mt-4" />
            <Card.Title style={{ fontSize: '1.5rem' }}>1. Add Bill Details</Card.Title>
            <Card.Text className="text-muted mb-4" style={{ fontSize: '1.2rem' }}>
              Upload, import or create vendor bills in a few clicks
            </Card.Text>
          </Card.Body>
        </Card>

        <Card
          className="text-center mb-4 border-0"
          style={{ width: '25rem', cursor: 'pointer', transition: 'background-color 0.3s ease' }}
          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#EFF5F5')}
          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
        >
          <Card.Body>
            <img src={"./assest/paybill.svg"} alt="Pay Icon" width={50} className="mb-3 mt-4" />
            <Card.Title style={{ fontSize: '1.5rem' }}>2. Pay Bills</Card.Title>
            <Card.Text className="text-muted mb-4" style={{ fontSize: '1.2rem' }}>
              Instantly pay bills from your connected bank accounts
            </Card.Text>
          </Card.Body>
        </Card>

        <Card
          className="text-center mb-4 border-0"
          style={{ width: '25rem', cursor: 'pointer', transition: 'background-color 0.3s ease' }}
          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#EFF5F5')}
          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
        >
          <Card.Body>
            <img src={"./assest/reconcilepayments.svg"} alt="Transfer Icon" width={50} className="mb-3 mt-4" />
            <Card.Title style={{ fontSize: '1.5rem' }}>3. Reconcile Payments</Card.Title>
            <Card.Text className="text-muted mb-4 " style={{ fontSize: '1.2rem' }}>
              Auto-reconcile payments with bills and sync with accounting software
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}

export default Payments;
