

// import React, { useState } from 'react';
// import { Container, Row, Col, Card, Modal, Button, Form } from 'react-bootstrap';

// const JobCard = ({ title, imgSrc, onClick }) => {
//   const [isHovered, setIsHovered] = useState(false);

//   return (
//     <Card
//       className="p-3 mb-4"
//       style={{
//         textAlign: 'left',
//         height: '300px',
//         position: 'relative',
//         transition: 'background-color 0.4s ease, transform 0.5s ease',
//         backgroundColor: isHovered ? '#B59BCD' : 'transparent',
//         transform: isHovered ? 'translateY(-18px)' : 'none'
//       }}
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//     >
//       <Card.Body className="d-flex flex-column">
//         <Card.Title className="fw-bold mt-4" style={{ fontSize: '1.7rem' }}>
//           {title}
//         </Card.Title>
//         <Card.Link
//           href="#"
//           className="px-3 py-2 fw-bold text-decoration-none"
//           style={{
//             color: '#FFF8F8',
//             fontSize: '1rem',
//             background: '#752abf',
//             borderRadius: '1.5rem',
//             width: 'fit-content',
//             position: 'absolute',
//             bottom: '15px',
//             left: '15px'
//           }}
//           onClick={onClick}
//         >
//           Get started
//         </Card.Link>
//         <Card.Img
//           src={imgSrc}
//           style={{
//             position: 'absolute',
//             bottom: '10px',
//             right: '0px',
//             height: '200px',
//             width: '200px',
//             objectFit: 'contain'
//           }}
//         />
//       </Card.Body>
//     </Card>
//   );
// };

// const PopUpModal = ({ show, handleClose, cardTitle }) => {
//   const renderForm = () => {
//     switch (cardTitle) {
//       case 'Electricity Bill':
//         return (
//           <>
//             <Form.Group className="mb-3" controlId="meterNumber">
//               <Form.Label>Meter Number</Form.Label>
//               <Form.Control type="text" placeholder="Enter your meter number" />
//             </Form.Group>
//             <Form.Group className="mb-3" controlId="amount">
//               <Form.Label>Amount</Form.Label>
//               <Form.Control type="number" placeholder="Enter the amount" />
//             </Form.Group>
//           </>
//         );
//       case 'Gas-Piped line Bill':
//         return (
//           <>
//             <Form.Group className="mb-3" controlId="gasNumber">
//               <Form.Label>Gas Connection Number</Form.Label>
//               <Form.Control type="text" placeholder="Enter your gas connection number" />
//             </Form.Group>
//             <Form.Group className="mb-3" controlId="amount">
//               <Form.Label>Amount</Form.Label>
//               <Form.Control type="number" placeholder="Enter the amount" />
//             </Form.Group>
//           </>
//         );
//       case 'LPG Gas Bill':
//         return (
//           <>
//             <Form.Group className="mb-3" controlId="lpgCylinderNumber">
//               <Form.Label>LPG Cylinder Number</Form.Label>
//               <Form.Control type="text" placeholder="Enter your LPG cylinder number" />
//             </Form.Group>
//             <Form.Group className="mb-3" controlId="amount">
//               <Form.Label>Amount</Form.Label>
//               <Form.Control type="number" placeholder="Enter the amount" />
//             </Form.Group>
//           </>
//         );
//       case 'Water Bill':
//         return (
//           <>
//             <Form.Group className="mb-3" controlId="waterConnectionNumber">
//               <Form.Label>Water Connection Number</Form.Label>
//               <Form.Control type="text" placeholder="Enter your water connection number" />
//             </Form.Group>
//             <Form.Group className="mb-3" controlId="amount">
//               <Form.Label>Amount</Form.Label>
//               <Form.Control type="number" placeholder="Enter the amount" />
//             </Form.Group>
//           </>
//         );
//       case 'Education Fees':
//         return (
//           <>
//             <Form.Group className="mb-3" controlId="studentId">
//               <Form.Label>Student ID</Form.Label>
//               <Form.Control type="text" placeholder="Enter student ID" />
//             </Form.Group>
//             <Form.Group className="mb-3" controlId="feesAmount">
//               <Form.Label>Fees Amount</Form.Label>
//               <Form.Control type="number" placeholder="Enter the amount" />
//             </Form.Group>
//           </>
//         );
//       case 'Mobile Recharge':
//         return (
//           <>
//             <Form.Group className="mb-3" controlId="mobileNumber">
//               <Form.Label>Mobile Number</Form.Label>
//               <Form.Control type="text" placeholder="Enter your mobile number" />
//             </Form.Group>
//             <Form.Group className="mb-3" controlId="amount">
//               <Form.Label>Recharge Amount</Form.Label>
//               <Form.Control type="number" placeholder="Enter the recharge amount" />
//             </Form.Group>
//           </>
//         );
//       case 'Mobile Postpaid Recharges':
//         return (
//           <>
//             <Form.Group className="mb-3" controlId="mobileNumber">
//               <Form.Label>Mobile Number</Form.Label>
//               <Form.Control type="text" placeholder="Enter your mobile number" />
//             </Form.Group>
//             <Form.Group className="mb-3" controlId="amount">
//               <Form.Label>Bill Amount</Form.Label>
//               <Form.Control type="number" placeholder="Enter the bill amount" />
//             </Form.Group>
//           </>
//         );
//       case 'Broadband/Datacard Bill':
//         return (
//           <>
//             <Form.Group className="mb-3" controlId="connectionNumber">
//               <Form.Label>Connection Number</Form.Label>
//               <Form.Control type="text" placeholder="Enter your connection number" />
//             </Form.Group>
//             <Form.Group className="mb-3" controlId="amount">
//               <Form.Label>Bill Amount</Form.Label>
//               <Form.Control type="number" placeholder="Enter the bill amount" />
//             </Form.Group>
//           </>
//         );
//       case 'DTH Recharge':
//         return (
//           <>
//             <Form.Group className="mb-3" controlId="customerId">
//               <Form.Label>Customer ID</Form.Label>
//               <Form.Control type="text" placeholder="Enter your customer ID" />
//             </Form.Group>
//             <Form.Group className="mb-3" controlId="amount">
//               <Form.Label>Recharge Amount</Form.Label>
//               <Form.Control type="number" placeholder="Enter the recharge amount" />
//             </Form.Group>
//           </>
//         );
//       case 'Credit Card Bill':
//         return (
//           <>
//             <Form.Group className="mb-3" controlId="creditCardNumber">
//               <Form.Label>Credit Card Number</Form.Label>
//               <Form.Control type="text" placeholder="Enter your credit card number" />
//             </Form.Group>
//             <Form.Group className="mb-3" controlId="amount">
//               <Form.Label>Bill Amount</Form.Label>
//               <Form.Control type="number" placeholder="Enter the bill amount" />
//             </Form.Group>
//           </>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <Modal show={show} onHide={handleClose} centered >
//       <Modal.Header closeButton>
//         <Modal.Title>{cardTitle}</Modal.Title>
//       </Modal.Header>
//       <Modal.Body style={{ boxShadow: '0 4px 10px #B59BCD', }}>
//         <Form>
//           {renderForm()}
//           <Button
//             style={{
//               background: '#752abf',
//               borderRadius: '1.5rem',
//               width: 'fit-content',
//               border: 'none'
//             }}
//             type="submit"
//           >
//             Submit
//           </Button>
//         </Form>
//       </Modal.Body>
//     </Modal>
//   );
// };

// const PopularSearches = () => {
//   const [showModal, setShowModal] = useState(false);
//   const [selectedTitle, setSelectedTitle] = useState('');

//   const handleOpenModal = (title) => {
//     setSelectedTitle(title);
//     setShowModal(true);
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//   };

//   return (
//     <div>
//       <Container className="my-5 py-5">
//         <Row className="g-4">
//           <Col md={6} lg={4}>
//             <h3
//               className="text-center fw-bold"
//               style={{
//                 fontSize: '3rem',
//                 marginTop: '0px',
//                 background: '#B59BCD',
//                 height: '300px',
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center'
//               }}
//             >
//               Utilities Bills
//             </h3>
//           </Col>
//           <Col md={6} lg={4}>
//             <JobCard title="Electricity Bill" imgSrc="./assest/electricity2.png" onClick={() => handleOpenModal('Electricity Bill')} />
//           </Col>
//           <Col md={6} lg={4}>
//             <JobCard title="Gas-Piped line Bill" imgSrc="./assest/gaspline1.png" onClick={() => handleOpenModal('Gas-Piped line Bill')} />
//           </Col>
//           <Col md={6} lg={4}>
//             <JobCard title="LPG Gas Bill" imgSrc="./assest/gascylinder1.png" onClick={() => handleOpenModal('LPG Gas Bill')} />
//           </Col>
//           <Col md={6} lg={4}>
//             <JobCard title="Water Bill" imgSrc="./assest/waterbill.webp" onClick={() => handleOpenModal('Water Bill')} />
//           </Col>
//           <Col md={6} lg={4}>
//             <JobCard title="Education Fees" imgSrc="./assest/educationfees.png" onClick={() => handleOpenModal('Education Fees')} />
//           </Col>
//         </Row>
//       </Container>
//       <Container className="my-5 py-5">
//         <Row className="g-4">
//           <Col md={6} lg={4}>
//             <h3
//               className="text-center fw-bold "
//               style={{
//                 fontSize: '3rem',
//                 marginTop: '0px',
//                 background: '#B59BCD',
//                 height: '300px',
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center'
//               }}
//             >
//               Telecom Bills
//             </h3>
//           </Col>
//           <Col md={6} lg={4}>
//             <JobCard title="Mobile Recharge" imgSrc="./assest/mobilerecharge1.png" onClick={() => handleOpenModal('Mobile Recharge')} />
//           </Col>
//           <Col md={6} lg={4}>
//             <JobCard title="Mobile Postpaid Recharges" imgSrc="./assest/mobilerecharge2.png" onClick={() => handleOpenModal('Mobile Postpaid Recharges')} />
//           </Col>
//           <Col md={6} lg={4}>
//             <JobCard title="Broadband/Datacard Bill" imgSrc="./assest/wifi2.png" onClick={() => handleOpenModal('Broadband/Datacard Bill')} />
//           </Col>
//           <Col md={6} lg={4}>
//             <JobCard title="DTH Recharge" imgSrc="./assest/dth1.png" onClick={() => handleOpenModal('DTH Recharge')} />
//           </Col>
//           <Col md={6} lg={4}>
//             <JobCard title="Credit Card Bill" imgSrc="./assest/creditcard1.png" onClick={() => handleOpenModal('Credit Card Bill')} />
//           </Col>
//         </Row>
//       </Container>
//       <PopUpModal show={showModal} handleClose={handleCloseModal} cardTitle={selectedTitle} />
//     </div>
//   );
// };

// export default PopularSearches; 
import React, { useState } from 'react';
import { Container, Row, Col, Card, Modal, Button, Form } from 'react-bootstrap';

const JobCard = ({ title, imgSrc, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Card
      className="p-3 mb-4"
      style={{
        textAlign: 'left',
        height: '300px',
        position: 'relative',
        transition: 'background-color 0.4s ease, transform 0.5s ease',
        backgroundColor: isHovered ? '#B59BCD' : 'transparent',
        transform: isHovered ? 'translateY(-18px)' : 'none'
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Card.Body className="d-flex flex-column">
        <Card.Title className="fw-bold mt-4" style={{ fontSize: '1.7rem' }}>
          {title}
        </Card.Title>
        <Card.Link
          href="#"
          className="px-3 py-2 fw-bold text-decoration-none"
          style={{
            color: '#FFF8F8',
            fontSize: '1rem',
            background: '#752abf',
            borderRadius: '1.5rem',
            width: 'fit-content',
            position: 'absolute',
            bottom: '15px',
            left: '15px'
          }}
          onClick={onClick}
        >
          Get started
        </Card.Link>
        <Card.Img
          src={imgSrc}
          style={{
            position: 'absolute',
            bottom: '10px',
            right: '0px',
            height: '180px',
            width: '200px',
            objectFit: 'contain'
          }}
        />
      </Card.Body>
    </Card>
  );
};

const PopUpModal = ({ show, handleClose, cardTitle }) => {
  const renderForm = () => {
    switch (cardTitle) {
      case 'Electricity Bill':
        return (
          <>
            <Form.Group className="mb-3" controlId="meterNumber">
              <Form.Label>Meter Number</Form.Label>
              <Form.Control type="text" placeholder="Enter your meter number" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="amount">
              <Form.Label>Amount</Form.Label>
              <Form.Control type="number" placeholder="Enter the amount" />
            </Form.Group>
          </>
        );
      case 'Gas-Piped line Bill':
        return (
          <>
            <Form.Group className="mb-3" controlId="gasNumber">
              <Form.Label>Gas Connection Number</Form.Label>
              <Form.Control type="text" placeholder="Enter your gas connection number" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="amount">
              <Form.Label>Amount</Form.Label>
              <Form.Control type="number" placeholder="Enter the amount" />
            </Form.Group>
          </>
        );
      case 'LPG Gas Bill':
        return (
          <>
            <Form.Group className="mb-3" controlId="lpgCylinderNumber">
              <Form.Label>LPG Cylinder Number</Form.Label>
              <Form.Control type="text" placeholder="Enter your LPG cylinder number" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="amount">
              <Form.Label>Amount</Form.Label>
              <Form.Control type="number" placeholder="Enter the amount" />
            </Form.Group>
          </>
        );
      case 'Water Bill':
        return (
          <>
            <Form.Group className="mb-3" controlId="waterConnectionNumber">
              <Form.Label>Water Connection Number</Form.Label>
              <Form.Control type="text" placeholder="Enter your water connection number" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="amount">
              <Form.Label>Amount</Form.Label>
              <Form.Control type="number" placeholder="Enter the amount" />
            </Form.Group>
          </>
        );
      case 'Education Fees':
        return (
          <>
            <Form.Group className="mb-3" controlId="studentId">
              <Form.Label>Student ID</Form.Label>
              <Form.Control type="text" placeholder="Enter student ID" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="feesAmount">
              <Form.Label>Fees Amount</Form.Label>
              <Form.Control type="number" placeholder="Enter the amount" />
            </Form.Group>
          </>
        );
      case 'Mobile Recharge':
        return (
          <>
            <Form.Group className="mb-3" controlId="mobileNumber">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control type="text" placeholder="Enter your mobile number" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="amount">
              <Form.Label>Recharge Amount</Form.Label>
              <Form.Control type="number" placeholder="Enter the recharge amount" />
            </Form.Group>
          </>
        );
      case 'Mobile Postpaid Recharges':
        return (
          <>
            <Form.Group className="mb-3" controlId="mobileNumber">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control type="text" placeholder="Enter your mobile number" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="amount">
              <Form.Label>Bill Amount</Form.Label>
              <Form.Control type="number" placeholder="Enter the bill amount" />
            </Form.Group>
          </>
        );
      case 'Broadband/Datacard Bill':
        return (
          <>
            <Form.Group className="mb-3" controlId="connectionNumber">
              <Form.Label>Connection Number</Form.Label>
              <Form.Control type="text" placeholder="Enter your connection number" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="amount">
              <Form.Label>Bill Amount</Form.Label>
              <Form.Control type="number" placeholder="Enter the bill amount" />
            </Form.Group>
          </>
        );
      case 'DTH Recharge':
        return (
          <>
            <Form.Group className="mb-3" controlId="customerId">
              <Form.Label>Customer ID</Form.Label>
              <Form.Control type="text" placeholder="Enter your customer ID" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="amount">
              <Form.Label>Recharge Amount</Form.Label>
              <Form.Control type="number" placeholder="Enter the recharge amount" />
            </Form.Group>
          </>
        );
      case 'Credit Card Bill':
        return (
          <>
            <Form.Group className="mb-3" controlId="creditCardNumber">
              <Form.Label>Credit Card Number</Form.Label>
              <Form.Control type="text" placeholder="Enter your credit card number" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="amount">
              <Form.Label>Bill Amount</Form.Label>
              <Form.Control type="number" placeholder="Enter the bill amount" />
            </Form.Group>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{cardTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ boxShadow: '0 4px 10px #B59BCD' }}>
        <Form>
          {renderForm()}
          <Button
            style={{
              background: '#752abf',
              borderRadius: '1.5rem',
              width: 'fit-content',
              border: 'none'
            }}
            type="submit"
          >
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const PopularSearches = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState('');

  const handleOpenModal = (title) => {
    setSelectedTitle(title);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <Container className="my-5 py-5">
        <Row className="g-4">
          <Col md={6} lg={4}>
            <h3
              className="text-center fw-bold"
              style={{
                fontSize: '3rem',
                marginTop: '0px',
                background: '#B59BCD',
                height: '300px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              Utilities Bills
            </h3>
          </Col>
          <Col md={6} lg={4}>
            <JobCard title="Electricity Bill" imgSrc="./assest/electricity2.png" onClick={() => handleOpenModal('Electricity Bill')} />
          </Col>
          <Col md={6} lg={4}>
            <JobCard title="Gas-Piped line Bill" imgSrc="./assest/gaspline1.png" onClick={() => handleOpenModal('Gas-Piped line Bill')} />
          </Col>
          <Col md={6} lg={4}>
            <JobCard title="LPG Gas Bill" imgSrc="./assest/gascylinder1.png" onClick={() => handleOpenModal('LPG Gas Bill')} />
          </Col>
          <Col md={6} lg={4}>
            <JobCard title="Water Bill" imgSrc="./assest/waterbill.png" onClick={() => handleOpenModal('Water Bill')} />
          </Col>
          <Col md={6} lg={4}>
            <JobCard title="Education Fees" imgSrc="./assest/educationfees.png" onClick={() => handleOpenModal('Education Fees')} />
          </Col>
        </Row>
      </Container>
      <Container className="my-5 py-5">
        <Row className="g-4">
          <Col md={6} lg={4}>
            <h3
              className="text-center fw-bold"
              style={{
                fontSize: '3rem',
                marginTop: '0px',
                background: '#B59BCD',
                height: '300px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              Telecom Bills
            </h3>
          </Col>
          <Col md={6} lg={4}>
            <JobCard title="Mobile Recharge" imgSrc="./assest/mobilerecharge1.png" onClick={() => handleOpenModal('Mobile Recharge')} />
          </Col>
          <Col md={6} lg={4}>
            <JobCard title="Mobile Postpaid Recharges" imgSrc="./assest/mobilerecharge2.png" onClick={() => handleOpenModal('Mobile Postpaid Recharges')} />
          </Col>
          <Col md={6} lg={4}>
            <JobCard title="Broadband/Datacard Bill" imgSrc="./assest/wifi2.png" onClick={() => handleOpenModal('Broadband/Datacard Bill')} />
          </Col>
          <Col md={6} lg={4}>
            <JobCard title="DTH Recharge" imgSrc="./assest/dth1.png" onClick={() => handleOpenModal('DTH Recharge')} />
          </Col>
          <Col md={6} lg={4}>
            <JobCard title="Credit Card Bill" imgSrc="./assest/creditcard2.png" onClick={() => handleOpenModal('Credit Card Bill')} />
          </Col>
        </Row>
      </Container>
      <PopUpModal show={showModal} handleClose={handleCloseModal} cardTitle={selectedTitle} />
    </div>
  );
};

export default PopularSearches;
