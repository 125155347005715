// import React from 'react';
// import { Container, Row, Col, ListGroup } from 'react-bootstrap';
// import { BiCheckCircle } from 'react-icons/bi'; 

// function GetPaid() {
//   return (
//     <div className="d-flex">
//            <Container style={{ marginLeft: '115px', alignItems: 'flex-start' }} className="">
//       <Row>
//         <Col>
//           <h6 className=" mb-4 mt-5" style={{ color:'#752abf', fontSize: '1.1rem' }}>GET PAID</h6>
//           <h2 className="mb-4" style={{ fontWeight: 'bold', fontSize:'2.5rem'}}>Get Paid Faster, Directly In<br></br> Your Bank Account</h2>
//              <p className="text-muted mb-3" style={{fontSize: '1.2rem' }}>  Offer customers the choice of multiple payment modes, and<br></br> receive the settlement directly into your bank account.</p>
//           <ListGroup variant="flush">
//             <ListGroup.Item className="border-0">
//               <div className="d-flex align-items-start">
//                 <BiCheckCircle className="text-success me-3 mt-1" size={24} /> 
//                 <p className="text-muted mb-0" style={{fontSize: '1.2rem' }}>GST-compliant invoices with automated payables<br></br> tracking</p>
//               </div>
//             </ListGroup.Item>
//             <ListGroup.Item className="border-0">
//               <div className="d-flex align-items-start">
//                 <BiCheckCircle className="text-success me-3 mt-1" size={25} />
//                 <p className="text-muted mb-0" style={{fontSize: '1.2rem' }}>Support for net banking, UPI, credit or debit cards</p>
//               </div>
//             </ListGroup.Item>
//             <ListGroup.Item className="border-0">
//               <div className="d-flex align-items-start">
//                 <BiCheckCircle className="text-success me-3 mt-1" size={25} />
//                 <p className="text-muted mb-0" style={{fontSize: '1.2rem' }}>Support for split payments or adjustments against<br></br> credit notes</p>
//               </div>
//             </ListGroup.Item>
//             <ListGroup.Item className="border-0">
//               <div className="d-flex align-items-start">
//                 <BiCheckCircle className="text-success me-3 mt-1" size={25} />
//                 <p className="text-muted mb-0" style={{fontSize: '1.2rem' }}>Option for instant settlement.</p>
//               </div>
//             </ListGroup.Item>
//             <ListGroup.Item className="border-0">
//               <div className="d-flex align-items-start">
//                 <BiCheckCircle className="text-success me-3 mt-1" size={25} />
//                 <p className="text-muted mb-5" style={{fontSize: '1.2rem' }}>Automated reconciliation and settlement</p>
//               </div>
//             </ListGroup.Item>
//           </ListGroup>
//         </Col>
//       </Row>
//     </Container>

//     <Container className="my-5 py-5" style={{ overflow: 'hidden' }}>
//         <Row>
//           <Col className="d-flex justify-content-center mb-5">
//             <video
//               src="./assest/animation5.mp4"
//               style={{
//                 width: '100%',
//                 maxWidth: '400px',
//                 height: '450px',
//                 objectFit: 'cover',
//               }}
//               autoPlay
//               muted
//               loop
//             />
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// }
// export default GetPaid;


// import React from 'react';
// import { Container, Row, Col, ListGroup } from 'react-bootstrap';
// import { BiCheckCircle } from 'react-icons/bi';

// function GetPaid() {
//   return (
//     <div className="d-flex">
//       <Container className="mt-5">
//         <Row>
//           <Col>
//             <h6 className="mb-4 mt-5" style={{ color: '#752abf', fontSize: '1.1rem' }}>GET PAID</h6>
//             <h2 className="mb-4" style={{ fontWeight: 'bold', fontSize: '2.5rem' }}>Get Paid Faster, Directly In<br /> Your Bank Account</h2>
//             <p className="text-muted mb-3" style={{ fontSize: '1.2rem' }}>Offer customers the choice of multiple payment modes, and<br /> receive the settlement directly into your bank account.</p>
//             <ListGroup variant="flush">
//               <ListGroup.Item className="border-0">
//                 <div className="d-flex align-items-start">
//                   <BiCheckCircle className="text-success me-3 mt-1" size={24} />
//                   <p className="text-muted mb-0" style={{ fontSize: '1.2rem' }}>GST-compliant invoices with automated payables<br /> tracking</p>
//                 </div>
//               </ListGroup.Item>
//               <ListGroup.Item className="border-0">
//                 <div className="d-flex align-items-start">
//                   <BiCheckCircle className="text-success me-3 mt-1" size={25} />
//                   <p className="text-muted mb-0" style={{ fontSize: '1.2rem' }}>Support for net banking, UPI, credit or debit cards</p>
//                 </div>
//               </ListGroup.Item>
//               <ListGroup.Item className="border-0">
//                 <div className="d-flex align-items-start">
//                   <BiCheckCircle className="text-success me-3 mt-1" size={25} />
//                   <p className="text-muted mb-0" style={{ fontSize: '1.2rem' }}>Support for split payments or adjustments against<br /> credit notes</p>
//                 </div>
//               </ListGroup.Item>
//               <ListGroup.Item className="border-0">
//                 <div className="d-flex align-items-start">
//                   <BiCheckCircle className="text-success me-3 mt-1" size={25} />
//                   <p className="text-muted mb-0" style={{ fontSize: '1.2rem' }}>Option for instant settlement.</p>
//                 </div>
//               </ListGroup.Item>
//               <ListGroup.Item className="border-0">
//                 <div className="d-flex align-items-start">
//                   <BiCheckCircle className="text-success me-3 mt-1" size={25} />
//                   <p className="text-muted mb-5" style={{ fontSize: '1.2rem' }}>Automated reconciliation and settlement</p>
//                 </div>
//               </ListGroup.Item>
//             </ListGroup>
//           </Col>

//           <Col className="d-flex justify-content-center mb-5">
//             <img
//               src="./assest/np1.png"
//               alt="Illustrative visual"
//               style={{
//                 width: '100%',
//                 maxWidth: '300px',
//                 height: '350px',
//                 objectFit: 'cover',
//               }}
//             />
//           </Col>
//         </Row>
//       </Container>
//     </div>

//   );
// }



// export default GetPaid;
import React from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { BiCheckCircle } from 'react-icons/bi';

function GetPaid() {
  return (
    <div className="py-5">
      <Container>
        <Row className="align-items-center">
          {/* Text Content Column */}
          <Col xs={12} md={6} className="text-center text-md-start">
            <h6
              className="mb-3"
              style={{
                color: '#752abf',
                fontSize: '1.2rem',
                letterSpacing: '0.05em',
              }}
            >
              GET PAID
            </h6>
            <h2
              className="mb-4 fs-3 fs-md-4 fs-lg-5" // Responsive font size
              style={{
                fontWeight: 'bold',
                lineHeight: '1.4',
              }}
            >
              Get Paid Faster, Directly In <br className="d-none d-md-block" /> Your Bank Account
            </h2>
            <p
              className="text-muted mb-4"
              style={{
                fontSize: '1.1rem',
                lineHeight: '1.7',
              }}
            >
              Offer customers the choice of multiple payment modes and receive the settlement
              directly into your bank account.
            </p>

            {/* List of Benefits with Icons */}
            <ListGroup variant="flush" className="gap-3">
              {[
                'GST-compliant invoices with automated payables tracking.',
                'Support for net banking, UPI, credit or debit cards.',
                'Support for split payments or adjustments against credit notes.',
                'Option for instant settlement.',
                'Automated reconciliation and settlement.',
              ].map((item, index) => (
                <ListGroup.Item className="border-0 p-0" key={index}>
                  <div className="d-flex align-items-center">
                    {/* Icon on the left */}
                    <BiCheckCircle
                      className="text-success me-3" // 'me-3' adds margin to the right of the icon for spacing
                      style={{ fontSize: '1.5rem' }} // Consistent icon size for all
                    />
                    {/* Text aligned next to the icon */}
                    <p
                      className="text-muted mb-0"
                      style={{
                        fontSize: '1rem',
                        lineHeight: '1.5',
                        margin: 0,
                      }}
                    >
                      {item}
                    </p>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>

          {/* Image Column */}
          <Col xs={12} md={6} className="d-flex justify-content-center mt-4 mt-md-0">
            <img
              src="./assest/np1.png"
              alt="Illustrative visual"
              className="img-fluid"
              style={{
                maxWidth: '350px',
                height: 'auto',
                objectFit: 'contain',
                borderRadius: '8px',
              }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default GetPaid;
